import { Component } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { EstateControlService } from 'src/app/services/estate-control.service';
import { TabControlService } from 'src/app/services/tab-control.service';
import { createIDsValidatorEstate } from 'src/app/services/validators/custom.validators';
import { ValidationService } from 'src/app/services/validators/validation.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import * as Constants from "src/app/constants/constants";

@Component({
  selector: 'app-step6-witness',
  templateUrl: './step6-witness.component.html',
  styleUrls: ['./step6-witness.component.scss']
})
export class Step6WitnessComponent {
  checkerPath: string = '../../../../assets/fi_check.png';

  title: string = 'Witnesses to the Will';
  subtitle: string = 'Witnesses to the Will are mandatory. Please choose below if you want to add witnesses now or include them after the Will is prepared.';
  subtitleAlt: string = 'Witnesses to the Will are mandatory. Please add all witnesses you would like to include in the Will below:';

  witnessFormVisible: boolean = false;

  detailsTitle: string = 'Witness Details';

  titleLabel: string = 'Title';
  titleHint: string = 'Title';
  fNameLabel: string = 'First Name';
  fNameHint : string = 'First name';
  mNameLabel : string = 'Middle Name';
  mNameHint : string = 'Middle name';
  surnameLabel : string = 'Surname';
  surnameHint : string = 'Last name';
  phoneLabel : string = 'Mobile Number';
  emailLabel : string = 'Email Address';
  emailHint : string = 'Email address';

  idLabel: string = 'ID/Passport Number';
  idHint: string = 'Enter ID or Passport Number';
  postalLabel: string = 'Postal Address';
  postalHint: string = 'Enter Postal Address';

  idFileLabel: string = 'Upload copy of ID/Passport';
  kraFileLabel: string = 'Upload copy of KRA PIN';
  photoLabel: string = 'Upload Passport Photo';

  addWitnessText: string = '+  Add Another Witness';
  removeWitnessText: string = 'Remove Witness';
  removeButtonText: string = this.removeWitnessText;

  requiredFieldString: string = 'Required field';

  loadingText: string = 'Saving Documents';
  loadingRemove: boolean = false;
  addWitnesses: boolean = true;
  currentIndex: number = 0;


  formErrors: { [key: string]: string } = {
    Title: '',
    FirstName: '',
    MiddleName: '',
    LastName: '',
    Phone: '',
    Email: '',
    IDNo: '',
    KraPin: '',
    PostalAddress: '',

    IDNoImage: '',
    KraPinImage: '',
    PassportImage: ''
  };

  validationMessages: { [key: string]: {} } = {
    Title: { required: this.requiredFieldString },
    FirstName: { required: this.requiredFieldString },
    MiddleName: { required: this.requiredFieldString },
    LastName: { required: this.requiredFieldString },
    Phone: { required: this.requiredFieldString, pattern:'Invalid phone number', exist: 'This number is tied to an existing witness' },
    Email: { required: this.requiredFieldString, email: "Invalid email address", exist: 'This email is tied to an existing witness' },
    IDNo:  { required: this.requiredFieldString, min:'Invalid ID or Passport number', exist: 'This ID is tied to an existing witness' },
    KraPin: { required: this.requiredFieldString, pattern:'Invalid KRA PIN', exist: 'This PIN is tied to an existing witness' },
    PostalAddress: { required: this.requiredFieldString },

    IDNoImage: { required: this.requiredFieldString },
    KraPinImage: { required: this.requiredFieldString },
    PassportImage: { required: this.requiredFieldString }
  };


  witnessForm = this._formBuilder.group({
  }) as FormGroup & { [key: string]: AbstractControl };

  constructor(
    private _formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private tabControlService: TabControlService,
    public estateControlService: EstateControlService,
    private validationService: ValidationService,
    private apiService: ApiService,
    private route: ActivatedRoute
  ) {
    this.populateData();
  }

  async ngOnInit(): Promise<void> {
    if (this.witnesses && this.witnesses.length > 0) {
      // Filter out the unverified testators and reassign the array
      const dat = this.witnesses.filter((witness: any) => witness.verified == true);
      await this.estateControlService.updateRecordWill(6, dat);

      const firstObject = this.witnesses[0];

      if (firstObject && firstObject.verified == true) {
        this.addWitnessesArray(firstObject);
        // Add witnesses from storage
        this.witnesses.forEach((witness: any, index: number) => {
          if (index > 0 && witness.verified == true) {
            const fields = this.witnessForm.get('witnesses') as FormArray;
            fields.push(this.createWitnessFormGroup(witness));
            this.witnessForm.setControl('witnesses', fields);
          }
        });
        this.witnessFormVisible = true;
      }
    }

    this.witnessForm.valueChanges.subscribe(()=> {
      this.logErrors();
    })
  }

  logErrors() {
    this.formErrors = this.validationService.logValidationErrors(
      this.witnessForm,
      this.formErrors,
      this.validationMessages
    );
  }

  get witnesses(): any {
    return this.estateControlService.witnesses;
  }
  get loading(): boolean {
    return this.notificationService.loading;
  }
  get loadingTitles(): boolean {
    return this.tabControlService.loadingTitles;
  }
  get TitleOptions(): any {
    return this.tabControlService.TitleOptions;
  }

  get saveProgress(): ()=> void {
    return this.estateControlService.saveProgress;
  }

  idObject: any = null;
  kraObject: any = null;
  photoObject: any = null;

  updateIDObject(object: any): void {
    this.idObject = object;
  }
  updateKraObject(object: any): void {
    this.kraObject = object;
  }
  updatePhotoObject(object: any): void {
    this.photoObject = object;
  }

  async populateData(): Promise<void> {
    if (this.TitleOptions.length === 0) {
      this.tabControlService.loadingTitles = true;
      await this.tabControlService.getTitlesList();
      this.tabControlService.loadingTitles = false;
    }
  }

  async populateForm(): Promise<void> {
    if (!(this.witnesses && this.witnesses.length > 0)) {
      this.addWitnessesArray();
    }
    this.witnessFormVisible = true;
  }

  onOptionChange(value: boolean): void {
    this.addWitnesses = value;
  }

  addWitnessesArray = (witness: any = null) => {
    this.witnessForm.addControl('witnesses', this._formBuilder.array([
      this.createWitnessFormGroup(witness)
    ]));
  }
  createWitnessFormGroup(witness: any = null): FormGroup {
    let form: FormGroup;

    // console.log('scajhbusdycbudsb ', witness);

    if (witness) {
      form = this._formBuilder.group({
        Title: [`${witness.Title || ''}`],
        FirstName: [`${witness.FirstName || ''}`, Validators.required],
        MiddleName: [`${witness.MiddleName || ''}`,],
        LastName: [`${witness.LastName || ''}`, Validators.required],
        Phone: [`${witness.Phone || ''}`, Validators.required],
        Email: [`${witness.Email || ''}`, [Validators.required, Validators.email]],
        IDNo: [`${witness.IDNo || ''}`, Validators.required],
        PostalAddress: [`${witness.PostalAddress || ''}`, Validators.required],

        IDNoImage: [],
        KraPinImage: [],
        PassportImage: []
      });

      if (witness.IDNoImage) {
        form.get('IDNoImage')?.setValue(witness.IDNoImage.stringValue);
        this.updateIDObject(witness.IDNoImage);
      }
      if (witness.KraPinImage) {
        form.get('KraPinImage')?.setValue(witness.KraPinImage.stringValue);
        this.updateKraObject(witness.KraPinImage);
      }
      if (witness.PassportImage) {
        form.get('PassportImage')?.setValue(witness.PassportImage.stringValue);
        this.updatePhotoObject(witness.PassportImage);
      }

      if (witness.verified) {
        this.validationService.addOrRemoveValidator(true, form.get('IDNoImage')!);
        this.validationService.addOrRemoveValidator(true, form.get('KraPinImage')!);
        this.validationService.addOrRemoveValidator(true, form.get('PassportImage')!); 
      }
      
    } else {
      if (
        this.witnesses && this.witnesses.length > 0 && this.witnesses[0].verified == false
      ) {
          this.estateControlService.refreshRecordWill(6);
      }

      let currentWitnesses = this.witnesses;
      let currentRecord: any = {verified: false, done: false};

      form = this._formBuilder.group({
        Title: [''],
        FirstName: ['', Validators.required],
        MiddleName: [''],
        LastName: ['', Validators.required],
        Phone: ['', Validators.required],
        Email: ['', [Validators.required, Validators.email]],

        IDNo: ['', Validators.required],
        PostalAddress: ['', Validators.required],

        IDNoImage: [],
        KraPinImage: [],
        PassportImage: []
      });

      if (
        this.witnesses.length == 0 ||
        this.witnesses.length > 0 && this.witnesses[this.witnesses.length-1].done == true
      ) {
        currentWitnesses.push(currentRecord);
        this.estateControlService.updateRecordWill(6, currentWitnesses);
      }
      
    }

    if (this.witnessForm && this.getWitnesses() && this.getWitnesses().length > 0) {
      this.validationService.addOrRemove3Validators(
        true,
        form.get('Email')!,
        [Validators.required,
          Validators.email,
          createIDsValidatorEstate('EMAIL', this.getWitnesses(), this.witnesses)
        ]
      );
      this.validationService.addOrRemoveSpecificValidators(
        true,
        form.get('Phone')!,
        [Validators.required,
          createIDsValidatorEstate('TEL', this.getWitnesses(), this.witnesses)
        ]
      );
      this.validationService.addOrRemove3Validators(
        true,
        form.get('IDNo')!,
        [Validators.required,
          Validators.min(4),
          createIDsValidatorEstate('ID', this.getWitnesses(), this.witnesses)
        ]
      );
    }

    form.updateValueAndValidity();

    return form;
  };
  async addNewWitness() {
    const fields = this.witnessForm.get('witnesses') as FormArray;
    fields.push(this.createWitnessFormGroup());
    this.witnessForm.setControl('witnesses', fields);
  }
  async removeWitness(i:number) {
    this.currentIndex = i;
    const fields = this.witnessForm.get('witnesses') as FormArray;
    let currentWitnesss = this.witnesses;
    
    if (currentWitnesss[i] && currentWitnesss[i].WitnessID) {
      this.removeButtonText = 'Removing Witness';
      this.loadingRemove = true;
      
      try {
        if (this.estateControlService.TrustID !== null) {
          const result = await this.estateControlService.removeRecord(
            'witness',
            currentWitnesss[i].WitnessID,
            parseInt(this.estateControlService.TrustID),
            environment.baseUrl + Constants.willWitnessURL
          );

          if (result === 1) {
            this.notificationService.viewToast('success', 'Witness removed successfully');
            fields.removeAt(i);
            // Remove settlor at index
            currentWitnesss.splice(i, 1);;
            await this.estateControlService.updateRecordWill(6, currentWitnesss);
          } else {
            this.notificationService.viewToast('error', 'Error removing record');
          }
        } else {
          console.log('Relevant IDs not found');
        }
      } catch (error) {
        console.log(':: Error !! ', error);
      }
      this.removeButtonText = 'Remove Witness';
      this.loadingRemove = false;
    } else {
      if (currentWitnesss[i] && currentWitnesss[i].done === false) {
        fields.removeAt(i);
        // Remove settlor at index
        currentWitnesss.splice(i, 1);;
        await this.estateControlService.updateRecordWill(6, currentWitnesss);
      } else {
        fields.removeAt(i);
      }
    }
  }
  getWitnesses() : FormArray {  
    return this.witnessForm.get("witnesses") as FormArray  
  }
  getWitnessFormGroup(index: number): FormGroup {
    const witnesses = this.witnessForm.get('witnesses') as FormArray;
    return witnesses.at(index) as FormGroup;
  }

  async saveWitness(): Promise<void> {
    if (this.witnesses[this.witnesses.length-1].verified == true) this.loadingText = 'Updating Witness';
    else this.loadingText = 'Saving Witness';

    let tsMessage = 'Witness details saved';
    this.notificationService.isLoading(true);

    try {
      const witnesses = this.witnessForm.get('witnesses') as FormArray;
      const record: FormGroup = this.getWitnessFormGroup(witnesses.length - 1) as FormGroup;
      
      let data = {
        ...record.value,
        ...{
          TrustID: this.estateControlService.TrustID,
        }
      };

      let currentWitnesses = this.witnesses;
      
      if (currentWitnesses[currentWitnesses.length-1].verified == true) {
        data.IDNoImage = this.idObject;
        data.KraPinImage = this.kraObject;
        data.PassportImage = this.photoObject;
      } else {
        data.IDNoImage = {name: '', stringValue: ''};
        data.KraPinImage = {name: '', stringValue: ''};
        data.PassportImage = {name: '', stringValue: ''};
      }

      if (currentWitnesses[currentWitnesses.length - 1].WitnessID) {
        data = {...data, ...{WitnessID: currentWitnesses[currentWitnesses.length - 1].WitnessID}};
      }
      
      const response = await this.apiService.postRequest(
        environment.baseUrl + Constants.willWitnessURL, data);
      
      // console.log('::::: RESPONSE ::::::\n', response);
      if (response.Status === 1) {
        const latestRecord = currentWitnesses[currentWitnesses.length - 1];
        let updatedRecord = {...latestRecord, ...record.value, ...{WitnessID: response.WitnessID}};

        Object.assign(currentWitnesses[currentWitnesses.length - 1], updatedRecord);

        await this.estateControlService.updateRecordWill(6, currentWitnesses);

        
        currentWitnesses = this.witnesses;
        let currentRecord = currentWitnesses[currentWitnesses.length-1];
        
        if (currentRecord.verified == true) { // uploaded docs
          tsMessage = 'Witness details updated';
          
          currentRecord.IDNoImage = this.idObject;
          currentRecord.KraPinImage = this.kraObject;
          currentRecord.PassportImage = this.photoObject;

          if (response.IdURL) {
            this.estateControlService.updateDocSummary(response.IdURL);
            currentRecord = {...currentRecord, ...{IDURL: response.IdURL}};
          }
          if (response.KraURL) {
            this.estateControlService.updateDocSummary(response.KraURL);
            currentRecord = {...currentRecord, ...{KraURL: response.KraURL}};
          }
          if (response.PassportURL) {
            this.estateControlService.updateDocSummary(response.PassportURL);
            currentRecord = {...currentRecord, ...{PassportURL: response.PassportURL}};
          }
          
          currentRecord.done = true;

        } else {
          currentRecord.verified = true;
          this.validationService.addOrRemoveValidator(true, this.getWitnessFormGroup(witnesses.length - 1).get('IDNoImage')!);
          this.validationService.addOrRemoveValidator(true, this.getWitnessFormGroup(witnesses.length - 1).get('KraPinImage')!);
          this.validationService.addOrRemoveValidator(true, this.getWitnessFormGroup(witnesses.length - 1).get('PassportImage')!);
          
          if (this.witnesses.length <= 1) this.estateControlService.updateWillProgress('Witnesses');
        }

        Object.assign(currentWitnesses[currentWitnesses.length - 1], currentRecord);
        await this.estateControlService.updateRecordWill(6, currentWitnesses);
        if (!environment.production) console.log('>> New Witnesses !! \n', this.estateControlService.witnesses);
        
        this.notificationService.viewToast('success', tsMessage);

      } else {
        this.notificationService.viewToast('error', response.Message);
      }
    } catch (error) {
      console.log(':: Error !! ', error);
      this.notificationService.viewToast('error', 'An unexpected error occurred');
    }
    this.notificationService.isLoading(false);
  }

  async submitForm(): Promise<void> {
    if (this.witnessFormVisible) {
      if (this.witnesses[this.witnesses.length-1].done) {
        this.navigateFunction();
      } else {
        this.saveWitness();
      }
    } else {
      if (this.addWitnesses) {
        this.populateForm();
      } else {
        this.navigateFunction();
      }
    }
  }

  async updateProgress(): Promise<void> {
    if (!this.witnesses[this.witnesses.length-1].done && this.witnessForm.valid) await this.submitForm();
    this.saveProgress();
  }

  async navigateFunction(): Promise<void> {
    await this.estateControlService.changeTabWill(7);
    this.estateControlService.page6Done = true;
  }
}
