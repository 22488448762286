import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as Constants from "src/app/constants/constants";
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TabControlService } from 'src/app/services/tab-control.service';
import { EstateControlService } from 'src/app/services/estate-control.service';
import { ValidationService } from 'src/app/services/validators/validation.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApiService } from 'src/app/services/api/api.service';
import { createIDsValidatorEstate } from 'src/app/services/validators/custom.validators';

@Component({
  selector: 'app-step3-executor',
  templateUrl: './step3-executor.component.html',
  styleUrls: ['./step3-executor.component.scss']
})
export class Step3ExecutorComponent {
  checkerPath: string = '../../../../assets/fi_check.png';

  formTitle: string = 'Executor of the Will';
  formSubtitle: string = 'Under Kenyan law, the minimum number of executors for a Will is one. However, it is advisable to appoint at least two executors to ensure that there is a backup in case the first executor is unable or unwilling to act.';

  detailsTitle: string = 'Executor Details';

  phoneLabel : string = 'Mobile Number';
  emailLabel : string = 'Email Address';
  emailHint : string = 'Email address';

  titleLabel: string = 'Title';
  titleHint: string = 'Title';
  fNameLabel: string = 'First Name';
  fNameHint : string = 'First name';
  mNameLabel : string = 'Middle Name';
  mNameHint : string = 'Middle name';
  surnameLabel : string = 'Surname';
  surnameHint : string = 'Last name';

  settlorRelationLabel: string = 'Relationship to the settlor';
  settlorRelationHint: string = 'Select Relationship';
  idLabel: string = 'ID/Passport Number';
  idHint: string = 'Enter ID or Passport Number';
  addressLabel: string = 'Physical Address';
  addressHint: string = 'Enter Physical address';
  postalLabel: string = 'Postal Address';
  postalHint: string = 'Enter Postal Address';

  addExecutorText: string = '+  Add Executor';
  removeExecutorText: string = 'Remove Executor';

  requiredFieldString: string = 'Required field';


  loadingRemove: boolean = false;
  currentIndex: number = 0;
  removeButtonText: string = 'Remove Executor';

  selfRecords: any = []; // For suggesting self
  recordUpdate: any;

  formErrors: { [key: string]: string } = {
    Title: '',
    FirstName: '',
    MiddleName: '',
    LastName: '',
    Phone: '',
    Email: '',

    Relationship: '',
    Other: '',
    IDNo: '',
    Address: '',
    PostalAddress: '',
  };

  validationMessages: { [key: string]: {} } = {
    Title: { required: this.requiredFieldString },
    FirstName: { required: this.requiredFieldString },
    MiddleName: { required: this.requiredFieldString },
    LastName: { required: this.requiredFieldString },
    Phone: { required: this.requiredFieldString, pattern:'Invalid phone number', exist: 'This number is tied to an existing executor' },
    Email: { required: this.requiredFieldString, email: "Invalid email address", exist: 'This email is tied to an existing executor' },
    
    Relationship:  { required: this.requiredFieldString },
    Other: {required: this.requiredFieldString},
    IDNo: { required: this.requiredFieldString, exist: 'This email is tied to an existing executor' },
    Address: { required: this.requiredFieldString },
    PostalAddress: { required: this.requiredFieldString },
  };


  executorForm = this._formBuilder.group({
    executors: this._formBuilder.array([
      this.createExecutorsFormGroup()
    ]),
  });

  constructor(
    private _formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private tabControlService: TabControlService,
    public estateControlService: EstateControlService,
    private validationService: ValidationService,
    private apiService: ApiService
  ) {
    this.populateData();
  }

  async ngOnInit(): Promise<void> {
    const records: any = this.estateControlService.testators;
    this.selfRecords.push(records[0]);
    if (records.length > 1) this.selfRecords.push(records[1]);

    await this.populateForm();

    this.executorForm.valueChanges.subscribe(()=> {
      this.logErrors();
    })
  }

  logErrors() {
    this.formErrors = this.validationService.logValidationErrors(
      this.executorForm,
      this.formErrors,
      this.validationMessages
    );
  }

  get loading(): boolean {
    return this.notificationService.loading;
  }
  get executors(): any {
    return this.estateControlService.executors;
  }
  get loadingTitles(): boolean {
    return this.tabControlService.loadingTitles;
  }
  get TitleOptions(): any {
    return this.tabControlService.TitleOptions;
  }
  get RelationOptions() {
    return this.tabControlService.RelationOptions;
  }
  Relationships: any = this.RelationOptions;

  get saveProgress(): ()=> void {
    return this.estateControlService.saveProgress;
  }

  async populateData(): Promise<void> {
    if (this.TitleOptions.length === 0) {
      this.tabControlService.loadingTitles = true;
      await this.tabControlService.getTitlesList();
      this.tabControlService.loadingTitles = false;
    }
  }
  async populateForm(): Promise<void> {
    if (this.executors && this.executors.length > 0) {
      let exec = this.executors;

      exec.forEach(async (executor: any, index: number) => {
        if (index > 0 && !executor.done) {
          exec.splice(index, 1);
          await this.estateControlService.updateRecordWill(3, exec);
        }
      });

      if (this.executors[0] && this.executors[0].done && this.executors[0].done == true) {
        const executorsArray = this.executorForm.get('executors') as FormArray;

        // Clear existing executors
        while (executorsArray.length !== 0) {
          executorsArray.removeAt(0);
        }
        
        // Add executors from storage
        this.executors.forEach((executor: any) => {
          if (executor.done) executorsArray.push(this.createExecutorsFormGroup(executor));
        });

        // Filter out the unverified executors and reassign the array
        const dat = this.executors.filter((executor: any) => executor.done == true);
        await this.estateControlService.updateRecordWill(3, dat);
      }
    }
  }

  onRelationshipChange(index: number): void {
    const value = this.getExecutorFormGroup(index)?.get('Relationship')?.value;
    if (value) {
      switch (value.toLowerCase()) {
        case 'self':
          const fields = this.executorForm.get('executors') as FormArray;
          this.recordUpdate = this.selfRecords[0];
          let counter: number = 0;
          
          const hasSelfRelationship = fields.controls.some(group => {  
            if (group.get('Relationship')?.value.toLowerCase() === 'self') {
              counter++;
            }
            return counter > 1;
          });
          // To autofill second testator
          if (hasSelfRelationship && this.selfRecords.length > 1)
            this.recordUpdate = this.selfRecords[1];
    
          fields.removeAt(index);
          fields.push(this.createExecutorsFormGroup(this.recordUpdate, value));
    
          this.executorForm.setControl('executors', fields);
          this.toggleFormControls(index, false);

          break;
        default:
          this.toggleFormControls(index, true);
          break;
      }
    }
  }

  toggleFormControls(index: number, enabled: boolean): void {
    if (enabled) { // Reset form when relationship != self
      const isSelfRecord: boolean = this.selfRecords.some((record: any) => {
        return this.getExecutorFormGroup(index).get('IDNo')?.value == record.IDNo;
      });

      if (isSelfRecord) {
        this.getExecutorFormGroup(index)?.get('Title')?.enable();
        this.getExecutorFormGroup(index)?.get('FirstName')?.enable();
        this.getExecutorFormGroup(index)?.get('MiddleName')?.enable();
        this.getExecutorFormGroup(index)?.get('LastName')?.enable();
        this.getExecutorFormGroup(index)?.get('Phone')?.enable();
        this.getExecutorFormGroup(index)?.get('Email')?.enable();
        this.getExecutorFormGroup(index).get('IDNo')?.enable();
        this.getExecutorFormGroup(index).get('Address')?.enable();
        this.getExecutorFormGroup(index).get('PostalAddress')?.enable();

        this.getExecutorFormGroup(index)?.get('Title')?.setValue('');
        this.getExecutorFormGroup(index)?.get('FirstName')?.setValue('');
        this.getExecutorFormGroup(index)?.get('MiddleName')?.setValue('');
        this.getExecutorFormGroup(index)?.get('LastName')?.setValue('');
        this.getExecutorFormGroup(index).get('Phone')?.setValue(' ');    
        this.getExecutorFormGroup(index)?.get('Email')?.setValue('');
        this.getExecutorFormGroup(index).get('IDNo')?.setValue('');
        this.getExecutorFormGroup(index)?.get('Address')?.setValue('');
        this.getExecutorFormGroup(index).get('PostalAddress')?.setValue('');
      }

    } else {
      // For 'self' option under relationship
      this.getExecutorFormGroup(index)?.get('Title')?.disable();
      this.getExecutorFormGroup(index)?.get('FirstName')?.disable();
      this.getExecutorFormGroup(index)?.get('MiddleName')?.disable();
      this.getExecutorFormGroup(index)?.get('LastName')?.disable();

      this.getExecutorFormGroup(index)?.get('Phone')?.disable();
      this.getExecutorFormGroup(index)?.get('Email')?.disable();  
      this.getExecutorFormGroup(index).get('IDNo')?.disable();
      this.getExecutorFormGroup(index).get('Address')?.disable();
      this.getExecutorFormGroup(index).get('PostalAddress')?.disable();
    }
    this.executorForm.updateValueAndValidity();
  }

  createExecutorsFormGroup(executor: any = null, value: any = null): FormGroup {
    let form: FormGroup;

    // console.log('scajhbusdycbudsb ', executor);

    if (executor) {
      form = this._formBuilder.group({
        Title: [`${executor.Title || ''}`],
        FirstName: [`${executor.FirstName || ''}`, Validators.required],
        MiddleName: [`${executor.MiddleName || ''}`],
        LastName: [`${executor.LastName || ''}`, Validators.required],
        Phone: [`${executor.Phone || ''}`, Validators.required],
        Email: [`${executor.Email || ''}`, [Validators.required, Validators.email]],
        
        IDNo: [`${executor.IDNo || ''}`, Validators.required],
        Relationship: [`${value ? value : executor.Relationship || ''}`, Validators.required],
        Other: [`${executor.Other || ''}`],
        
        Address: [`${executor.Address || ''}`, Validators.required],
        PostalAddress: [`${executor.PostalAddress || ''}`, Validators.required],
      });

    } else {
      if (
        this.executors && this.executors.length > 0 && this.executors[0].done == false
      ) {
          this.estateControlService.refreshRecordWill(3);
      }

      let currentExecutors = this.executors;
      let currentRecord: any = {done: false};

      form = this._formBuilder.group({
        Title: [''],
        FirstName: ['', Validators.required],
        MiddleName: [''],
        LastName: ['', Validators.required],
        Phone: ['', Validators.required],
        Email: ['', [Validators.required, Validators.email]],
        
        IDNo: ['', Validators.required],
        Relationship: ['', Validators.required],
        Other: [''],
        
        Address: ['', Validators.required],
        PostalAddress: ['', Validators.required],
      });

      if (
        this.executors.length == 0 ||
        this.executors.length > 0 && this.executors[this.executors.length-1].done == true
      ) {
        currentExecutors.push(currentRecord);
        this.estateControlService.updateRecordWill(3, currentExecutors);
      }
    }

    this.validationService.addOrRemoveValidationOnValue(
      form.get("Relationship")!,
      "Other",
      form,
      "Other"
    );

    if (this.executorForm && this.getExecutors() && this.getExecutors().length > 0) {
      this.validationService.addOrRemove3Validators(
        true,
        form.get('Email')!,
        [Validators.required,
          Validators.email,
          createIDsValidatorEstate('EMAIL', this.getExecutors(), this.executors)
        ]
      );
      this.validationService.addOrRemoveSpecificValidators(
        true,
        form.get('Phone')!,
        [Validators.required,
          createIDsValidatorEstate('TEL', this.getExecutors(), this.executors)
        ]
      );
      this.validationService.addOrRemoveSpecificValidators(
        true,
        form.get('IDNo')!,
        [Validators.required,
          createIDsValidatorEstate('ID', this.getExecutors(), this.executors)
        ]
      );

      // For 'self' option control
      const fields = this.executorForm.get('executors') as FormArray;
      let counter: number = 0;
    
      const hasSelfRelationship = fields.controls.some(group => {  
        if (group.get('Relationship')?.value.toLowerCase() === 'self') {
          counter++;
        }
        return counter == this.selfRecords.length;
      });

      if (hasSelfRelationship)
        this.Relationships = this.RelationOptions.filter((relation: string) => relation !== 'Self');
    }

    form.updateValueAndValidity();

    return form;
  };

  async addNewExecutor() {
    const fields = this.executorForm.get('executors') as FormArray;
    fields.push(this.createExecutorsFormGroup());
    this.executorForm.setControl('executors', fields);
  }

  async removeExecutor(i:number) {
    this.currentIndex = i;
    const fields = this.executorForm.get('executors') as FormArray;
    let currentExecutors = this.executors;
    
    if (currentExecutors[i] && currentExecutors[i].ExecutorID) {
      this.removeButtonText = 'Removing Executor';
      this.loadingRemove = true;
      
      try {
        if (this.estateControlService.TrustID !== null) {
          const result = await this.estateControlService.removeRecord(
            'executor',
            currentExecutors[i].ExecutorID,
            parseInt(this.estateControlService.TrustID),
            environment.baseUrl + Constants.willExecutorURL
          );

          if (result === 1) {
            this.notificationService.viewToast('success', 'Executor removed successfully');
            fields.removeAt(i);
            // Remove executor at index
            currentExecutors.splice(i, 1);;
            await this.estateControlService.updateRecordWill(3, currentExecutors);
          } else {
            this.notificationService.viewToast('error', 'Error removing record');
          }
        } else {
          console.log('Relevant IDs not found');
        }
      } catch (error) {
        console.log(':: Error !! ', error);
      }
      this.removeButtonText = 'Remove Executor';
      this.loadingRemove = false;
    } else {
      if (currentExecutors[i] && currentExecutors[i].done === false) {
        fields.removeAt(i);
        // Remove executor at index
        currentExecutors.splice(i, 1);;
        await this.estateControlService.updateRecordWill(3, currentExecutors);
      } else {
        fields.removeAt(i);
      }
    }
  }
  getExecutors() : FormArray {  
    return this.executorForm.get("executors") as FormArray  
  }
  getExecutorFormGroup(index: number): FormGroup {
    const executors = this.executorForm.get('executors') as FormArray;
    return executors.at(index) as FormGroup;
  }

  async saveExecutor(): Promise<void> {
    let tsMessage = 'Executor details saved';

    this.notificationService.isLoading(true);

    try {
      const executors = this.executorForm.get('executors') as FormArray;
      const record: FormGroup = this.getExecutorFormGroup(executors.length - 1) as FormGroup;

      let category;
      if (this.estateControlService.TrustID === null || executors.length - 1 === 0) {
        category = 'Primary Executor';
      } else {
        category = 'Secondary Executor';
      }
      
      let data = {
        ...record.getRawValue(),
        ...{
          TrustID: this.estateControlService.TrustID,
          Category: category
        }
      };

      if (data.Other && data.Other != '') {
        const other = data.Other;
        data.Relationship = other;
      }
      if (data.hasOwnProperty('Other')) {
        delete data['Other'];
      }

      let currentExecutors = this.executors;

      if (currentExecutors[currentExecutors.length - 1].ExecutorID) {
        data = {...data, ...{ExecutorID: currentExecutors[currentExecutors.length - 1].ExecutorID}};
      }
      
      const response = await this.apiService.postRequest(
        environment.baseUrl + Constants.willExecutorURL, data, false);
      
      // console.log('::::: RESPONSE ::::::\n', response);
      if (response.Status === 1) {
        const latestRecord = currentExecutors[currentExecutors.length - 1];
        let updatedRecord = {...latestRecord, ...record.getRawValue(), ...{ExecutorID: response.ExecutorID, Category: category}};
        updatedRecord.done = true;
        
        Object.assign(currentExecutors[currentExecutors.length - 1], updatedRecord);

        await this.estateControlService.updateRecordWill(3, currentExecutors);

        if (this.executors.length <= 1) this.estateControlService.updateWillProgress('Executors');
        this.notificationService.viewToast('success', tsMessage);

      } else {
        this.notificationService.viewToast('error', response.Message);
      }
    } catch (error) {
      console.log(':: Error !! ', error);
      this.notificationService.viewToast('error', 'An unexpected error occurred');
    }
    this.notificationService.isLoading(false);
  }

  async updateProgress(): Promise<void> {
    if (!this.executors[this.executors.length-1].done && this.executorForm.valid) await this.saveExecutor();
    this.saveProgress();
  }

  async submitForm(): Promise<void> {
    if (this.executors[this.executors.length-1].done) {
      this.navigateFunction();
    } else {
      this.saveExecutor();
    }
  }
  async navigateFunction(): Promise<void> {
    await this.estateControlService.changeTabWill(4);
    this.estateControlService.page3Done = true;
  }

}
