<div mat-dialog-title class="flex column">
    <p style="font-size: larger !important; color: #475467 !important; font-weight: 600; margin-top: -20px;">
      {{title}}
    </p>
    <div class="flex line" style="margin-bottom: 5px; margin-top: 10px;"></div>
  
  </div>
  
  <mat-dialog-content class="scrollable-content">
    <div class="flex center sticky-container">
      <ng-container *ngIf="contentTemplate">
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      </ng-container>
    </div>
  </mat-dialog-content>
    
  <mat-dialog-actions align="end">
      <!-- <button mat-button (click)="onCancel()" [disabled]="loading">Cancel</button> -->
      <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="loading">
        <span *ngIf="!loading">Done</span>
      </button>
  </mat-dialog-actions>