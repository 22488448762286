import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api/api.service';
import { TabControlService } from 'src/app/services/tab-control.service';
import { ValidationService } from 'src/app/services/validators/validation.service';
import { NotificationService } from 'src/app/services/notification.service';

import { environment } from 'src/environments/environment';
import * as Constants from "src/app/constants/constants";
import { formatDate } from 'src/app/util/Helper';
import { createIDsValidator, createIDsValidatorEstate } from 'src/app/services/validators/custom.validators';

@Component({
  selector: 'app-step3-beneficiary',
  templateUrl: './step3-beneficiary.component.html',
  styleUrls: ['./step3-beneficiary.component.scss']
})
export class Step3BeneficiaryComponent {

  checkerPath: string = '../../../../assets/fi_check.png';

  title: string = 'Add Beneficiaries.';
  subtitle: string = 'Add one or more beneficiaries below';

  benTypeLabel: string = 'Select Beneficiary Type';
  benTypeHint: string = 'Beneficiary type';

  purposeLabel: string = 'Purpose of Trust';
  purposeHint: string = 'Select purpose of trust';
  purposeSubtext: string = 'Select all applicable purposes for this beneficiary';

  otherPurposeLabel: string = 'Other Purpose';
  otherPurposeHint: string = 'Please specify other purpose';

  titleLabel: string = 'Title';
  titleHint: string = 'Title';
  fNameLabel: string = 'First Name';
  fNameHint : string = 'First name';
  mNameLabel : string = 'Middle Name';
  mNameHint : string = 'Middle name';
  surnameLabel : string = 'Surname';
  surnameHint : string = 'Last name';
  genderLabel: string = 'Gender';
  genderHint: string = 'Select gender';
  phoneLabel : string = 'Primary Phone Number';
  emailLabel : string = 'Email Address';
  emailHint : string = 'Email address';
  dobLabel: string = 'Date of birth';
  dobHint: string = '01/01/1990';
  shareAllocationLabel: string = 'Share Allocation (%)';
  shareAllocationHint: string = 'eg 100';
  shareAllocationSubtext: string = 'The Share allocation for all beneficiaries should total 100%';

  institutionNameLabel: string = 'Name of Institution';
  businessNatureLabel: string = 'Nature of Business';
  orgRegNumLabel: string = 'Organization Registration Number';
  orgAddressLabel: string = 'Organization Physical Address';

  institutionNameHint: string = 'Enter name';
  businessNatureHint: string = 'Enter nature of business';
  orgRegNumHint: string = 'Enter registration Number';
  orgAddressHint: string = 'Enter physical address';

  additionalDetailsText: string = 'Upload Documents';
  // Senior
  idLabel: string = 'ID/Passport Number';
  idHint: string = 'Enter ID or Passport Number';
  kraPinLabel: string = 'KRA PIN';
  kraHint: string = 'Enter KRA PIN';
  idFileLabel: string = 'Upload Copy of ID/Passport';
  // Minor
  birthCertLabel: string = 'Upload Birth Certificate of Child';

  regCertFileLabel: string = 'Upload Registration Certificate';
  kraFileLabel: string = 'Upload KRA PIN';

  addBenText: string = 'Add Another Beneficiary';
  removeBenText: string = 'Remove Beneficiary';

  addText: string = 'Beneficiary details saved';
  updateText: string = 'Beneficiary details updated'
  
  requiredFieldString: string = 'Required field';
  invalidDocString: string = 'Please attach a valid document';

  pushDocs: boolean = false;
  pushContactInfo: boolean = false;
  buttonDisabled: boolean = true;
  addDisabled: boolean = true;

  trustPurposeOptionsEmpty: boolean = true;
  loadingTrustPurposeOptions: boolean = false;


  formErrors: { [key: string]: string } = {
    Category: '',
    TrustPurpose: '',
    OtherPurpose: '',
    Title: '',
    FirstName: '',
    MiddleName: '',
    LastName: '',
    Gender: '',
    Phone: '',
    Email: '',
    DOB: '',
    PercShare: '',
    IDNo: '',
    KraPin: '',
    IDNoImage: '',
    BirthCertImage: '',
    BirthCertNo: '',

    InstitutionName: '',
    BusinessNature: '',
    OrgRegNum: '',
    OrgAddress: '',
    RegCertImage: '',
    KraPinImage: '',
  };

  validationMessages: { [key: string]: {} } = {
    Category: { required: this.requiredFieldString },
    TrustPurpose: { required: this.requiredFieldString },
    OtherPurpose: { required: this.requiredFieldString },
    Title: { required: this.requiredFieldString },
    FirstName: { required: this.requiredFieldString },
    MiddleName: { required: this.requiredFieldString },
    LastName: { required: this.requiredFieldString },
    Gender: { required: this.requiredFieldString },
    Phone: { required: this.requiredFieldString, pattern:'Invalid phone number', exist: 'This number is tied to an existing beneficiary' },
    Email: { required: this.requiredFieldString, email: "Invalid email address" },
    DOB:  { required: this.requiredFieldString },
    PercShare:  { required: this.requiredFieldString, max: `Shares allocated cannot exceed ${this.tabControlService.finalSharesRemaining}%` },
    IDNo:  { required: this.requiredFieldString, pattern:'Invalid ID or Passport number', exist: 'This ID is tied to an existing beneficiary' },
    KraPin: { required: this.requiredFieldString, pattern:'Invalid KRA PIN', exist: 'This PIN is tied to an existing beneficiary' },
    IDNoImage: { required: this.requiredFieldString, invalid: this.invalidDocString },
    BirthCertImage: { required: this.requiredFieldString, invalid: this.invalidDocString },
    BirthCertNo: { required: this.requiredFieldString },

    InstitutionName: { required: this.requiredFieldString },
    BusinessNature: { required: this.requiredFieldString },
    OrgRegNum: { required: this.requiredFieldString },
    OrgAddress: { required: this.requiredFieldString },

    RegCertImage: { required: this.requiredFieldString, invalid: this.invalidDocString },
    KraPinImage: { required: this.requiredFieldString, invalid: this.invalidDocString },
  };

  benForm = this._formBuilder.group({
    beneficiaries: this._formBuilder.array([
      this.createBensFormGroup()
    ]),
  });

  over18: any = '';
  loading: boolean = false;
  loadingText: string = 'Saving Beneficiary';
  currentRecordSaved: boolean = false;

  loadingRemove: boolean = false;
  removeButtonText: string = 'Remove Beneficiary';
  removeContactText: string = 'Remove Contact';
  navigationRequested: boolean = false;
  currentIndex: number = 0;
  shouldInsert: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private tabControlService: TabControlService,
    private validationService: ValidationService,
    private apiService: ApiService,
  ) { }

  ngOnInit(): void {
    if (this.beneficiaries && this.beneficiaries.length > 0) {
      if (this.beneficiaries[0].personalDone
        && this.beneficiaries[0].personalDone == true) {
        const beneficiariesArray = this.benForm.get('beneficiaries') as FormArray;

        // Clear existing beneficiaries
        while (beneficiariesArray.length !== 0) {
          beneficiariesArray.removeAt(0);
        }

        let totalShares = 0;

        // Add beneficiaries from storage
        this.beneficiaries.forEach((beneficiary: any) => {
          beneficiariesArray.push(this.createBensFormGroup(beneficiary));
          totalShares = totalShares + parseInt(beneficiary.PercShare);
        });

        const remaining = 100 - totalShares;

        this.tabControlService.sharesRemaining = remaining;
        this.tabControlService.updateFinalRemainingShares(remaining);
        this.tabControlService.updateStorageObject();

        // console.log('Bonobos ', this.tabControlService.finalSharesRemaining);
      }
    }

    if (this.tabControlService.activeTab == 3) {
      this.populateData();
    }
    this.updateValidity();

    this.benForm.statusChanges.subscribe((result) => {
      this.updateValidity(result);
      // console.log('::::::::::::::::::::::::::::: FORM + CONTROLS DEBUG\n', this.benForm);
    });

    this.benForm.valueChanges.subscribe((form) => {
      this.logErrors();
      
      const bens = this.benForm.get('beneficiaries') as FormArray;
      
      bens.controls.forEach((control, index) => {
        const shareAllocation = control.get('PercShare');

        if (!(shareAllocation === null || shareAllocation.value === '')) {
          control.patchValue({
            PercShare: shareAllocation.value
            .replace(/[^\d]/g, '') // Allow only numbers (remove any non-digit characters)
            .replace(/^0+/, '') // Remove leading zeros
            .replace(/[1-9]\d*/, (match: string) => (parseInt(match) > this.tabControlService.finalSharesRemaining ? this.tabControlService.finalSharesRemaining : match)) // Replace numbers greater than 100 with '100'
          }, {emitEvent: false});
        }
      });
      this.updateValidity();
    });
  }
  
  getDateDifference(date: string) :boolean {
    const currentDate = new Date();
    const dobDate = new Date(date);
    const ageDifferenceInMilliseconds = currentDate.getTime() - dobDate.getTime();
    const ageDate = new Date(ageDifferenceInMilliseconds);

    // Calculate age based on the year.
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);
    if (age < 18) {
      return false;
    } else {
      return true;
    }
  }

  idObject: any = null;
  kraObject: any = null;

  birthCertObject: any = null;
  regCertObject: any = null;

  businessNatureEmpty: boolean = true;

  get TrustPurposeOptions(): any {
    return this.tabControlService.TrustPurposeOptions;
  }
  get beneficiaries(): any {
    return this.tabControlService.beneficiaries;
  }
  get finalRemainingShares() {
    return this.tabControlService.finalSharesRemaining;
  }
  get remainingShares() {
    return this.tabControlService.sharesRemaining;
  }
  get minDate() {
    return this.tabControlService.minDOBDate;
  }
  get maxDate() {
    return this.tabControlService.maxDOBDate;
  }

  get loadingTitles(): boolean {
    return this.tabControlService.loadingTitles;
  }
  get TitleOptions(): any {
    return this.tabControlService.TitleOptions;
  }
  get BeneficiaryOptions():any {
    return this.tabControlService.BeneficiaryOptions;
  }
  get GenderOptions():any {
    return this.tabControlService.GenderOptions;
  }

  get loadingBizNature(): boolean {
    return this.tabControlService.loadingBizNature;
  }
  get BusinessNatureOptions(): any {
    return this.tabControlService.BusinessNatureOptions;
  }

  get saveProgress(): ()=> void {
    return this.tabControlService.saveProgress;
  }

  logErrors() {
    this.formErrors = this.validationService.logValidationErrors(
      this.benForm,
      this.formErrors,
      this.validationMessages
    );
  }

  updateValidity(result: any = '') {
    if (result !== '') {
      // this.benForm.updateValueAndValidity();
      // console.log('@ Checking validity : ', result, '\n\n')
    }
    
    if (this.benForm.valid) {
      if (this.loading) {
        this.buttonDisabled = true;
      } else {
        this.buttonDisabled = false;
      }
    } else {
      this.buttonDisabled = true;
    }
    
  };

  async populateData(): Promise<void> {
    if (this.TrustPurposeOptions.length === 0) {
      await this.getTrustPurposeOptionsList().then(()=> {
        if (this.TrustPurposeOptions.length === 0) this.notificationService.viewToast('error', 'Failed to load some data');
      });
    }
    if (this.TitleOptions.length === 0) {
      this.tabControlService.loadingTitles = true;
      await this.tabControlService.getTitlesList();
      this.tabControlService.loadingTitles = false;
    }
    if (this.BusinessNatureOptions.length === 0) {
      this.tabControlService.loadingBizNature = true;
      await this.tabControlService.getBusinessNatOptionsList();
      this.tabControlService.loadingBizNature = false;
    }
  }

  async getTrustPurposeOptionsList() {
    this.loadingTrustPurposeOptions = true;
    try {
      const response = await this.apiService.getRequest(
        environment.baseUrl + Constants.purposeURL
      );

      if (response.Status === 1) {
        this.tabControlService.TrustPurposeOptions = response.Data;
        this.trustPurposeOptionsEmpty = false;
      } else {
        console.log('>>> Error : ', response.Message);
        this.trustPurposeOptionsEmpty = true;
      }
    } catch(error) {
      console.log('>>> Error !! ', error);
    }

    
    this.loadingTrustPurposeOptions = false;
  }

  organizeValues(items: any): string {
    try {
      let output: string = items[0];
      for (let i=1; i<items.length; i++) {
        output = output + ', ' +items[i];
      }
      // console.log('Final output : ', output);
      return output;
    } catch (error) {
      console.log('Error : ', error);
      return '';
    }
  }

  arrayContainsValue(arr: string | null | undefined, value: string) {
    return arr && arr.includes(value);
  }

  updateIDObject(object: any): void {
    this.idObject = object;
    // console.log('ID doc updated: ', this.idObject.name);
  }
  updateKraObject(object: any): void {
    this.kraObject = object;
  }
  updateBirthCertObject(object: any): void {
    this.birthCertObject = object;
  }
  updateRegCertObject(object: any): void {
    this.regCertObject = object;
  }

  onBenTypeChange(index: number): void {
    const beneType = this.getBensFormGroup(index).get('Category');

    if (beneType) {
      if (beneType.value.toLowerCase() === 'individual') {
      this.getBensFormGroup(index).get('InstitutionName')?.clearValidators();
      this.getBensFormGroup(index).get('BusinessNature')?.clearValidators();
      this.getBensFormGroup(index).get('OrgRegNum')?.clearValidators();
      this.getBensFormGroup(index).get('KraPin')?.clearValidators();
      this.getBensFormGroup(index).get('OrgAddress')?.clearValidators();

      this.getBensFormGroup(index).get('InstitutionName')?.setValue('');
      this.getBensFormGroup(index).get('BusinessNature')?.setValue('')
      this.getBensFormGroup(index).get('OrgRegNum')?.setValue('')
      this.getBensFormGroup(index).get('KraPin')?.setValue('')
      this.getBensFormGroup(index).get('OrgAddress')?.setValue('')

        this.validationService.addOrRemoveValidator(true,
          this.getBensFormGroup(index).get('FirstName')!);
        this.validationService.addOrRemoveValidator(true,
          this.getBensFormGroup(index).get('LastName')!);
        this.validationService.addOrRemoveValidator(true,
          this.getBensFormGroup(index).get('DOB')!);

      } else {
        this.getBensFormGroup(index).get('FirstName')?.clearValidators();
        this.getBensFormGroup(index).get('LastName')?.clearValidators();
        this.getBensFormGroup(index).get('DOB')?.clearValidators();
        this.getBensFormGroup(index).get('Gender')?.clearValidators();

        this.getBensFormGroup(index).get('Title')?.setValue('');
        this.getBensFormGroup(index).get('FirstName')?.setValue('');
        this.getBensFormGroup(index).get('MiddleName')?.setValue('');
        this.getBensFormGroup(index).get('LastName')?.setValue('');
        this.getBensFormGroup(index).get('Phone')?.setValue('');
        this.getBensFormGroup(index).get('Email')?.setValue('');
        this.getBensFormGroup(index).get('DOB')?.setValue('');
        this.getBensFormGroup(index).get('Gender')?.setValue('');

        this.validationService.addOrRemoveValidator(true,
          this.getBensFormGroup(index).get('InstitutionName')!);
        this.validationService.addOrRemoveValidator(true,
          this.getBensFormGroup(index).get('BusinessNature')!);
        this.validationService.addOrRemoveValidator(true,
          this.getBensFormGroup(index).get('OrgRegNum')!);

        this.validationService.addOrRemove3Validators(
          true,
          this.getBensFormGroup(index).get('KraPin')!,
          [Validators.required,
            Validators.pattern(/^[AP]\d{9}[A-Z]$/),
            createIDsValidator('PIN', this.getBens(), this.beneficiaries)
          ]
        );

        this.validationService.addOrRemoveValidator(true,
          this.getBensFormGroup(index).get('OrgAddress')!);

      }
    }
    this.updateValidity();
  }

  createBensFormGroup(beneficiary: any = null): FormGroup {
    let form: FormGroup;

    if (beneficiary) {
      form = this._formBuilder.group({
        Category: [`${beneficiary.Category || this.BeneficiaryOptions[0]}`, Validators.required],
        TrustPurpose: [`${beneficiary.TrustPurpose || ''}`, Validators.required],
        OtherPurpose: [`${beneficiary.OtherPurpose || ''}`],
        Title: [`${beneficiary.Title || ''}`],
        FirstName: [`${beneficiary.FirstName || ''}`],
        MiddleName: [`${beneficiary.MiddleName || ''}`],
        LastName: [`${beneficiary.LastName || ''}`],
        Gender: [`${beneficiary.Gender || ''}`],
        Phone: [`${beneficiary.Phone || ''}`],
        Email: [`${beneficiary.Email || ''}`, Validators.email],
        DOB: [`${beneficiary.DOB || ''}`],
        PercShare: [`${beneficiary.PercShare || ''}`, [Validators.required, Validators.max(this.finalRemainingShares)]],
  
        IDNo: [`${beneficiary.IDNo || ''}`],
        KraPin: [`${beneficiary.KraPin || ''}`],
        IDNoImage: [''],

        BirthCertNo: [`${beneficiary.BirthCertNo || ''}`],
        BirthCertImage: [''],
  
        InstitutionName: [`${beneficiary.InstitutionName || ''}`],
        BusinessNature: [`${beneficiary.BusinessNature || ''}`],
        OrgRegNum: [`${beneficiary.OrgRegNum || ''}`],
        OrgAddress: [`${beneficiary.OrgAddress || ''}`],
  
        ContactPersons: this._formBuilder.array([
          this.createContactsGroup()
        ]),
  
        RegCertImage: [''],
        KraPinImage: ['']
      });

      if (beneficiary.ContactPersons && beneficiary.ContactPersons.length > 0) {
        const contactArray = form.get('ContactPersons') as FormArray;

        while (contactArray.length !== 0) {
          contactArray.removeAt(0);
        }

        beneficiary.ContactPersons.forEach((contact: any) => {
          contactArray.push(this.createContactsGroup(contact));
        });
      }

      if (beneficiary.IDNoImage) {
        if (beneficiary.IDNoImage.stringValue && beneficiary.IDNoImage.stringValue.length > 0) {
          form.get('IDNoImage')?.setValue(beneficiary.IDNoImage.stringValue);
        }
        this.updateIDObject(beneficiary.IDNoImage);
      }
      if (beneficiary.KraPinImage) {
        if (beneficiary.KraPinImage.stringValue && beneficiary.KraPinImage.stringValue.length > 0) {
          form.get('KraPinImage')?.setValue(beneficiary.KraPinImage.stringValue);
        }
        this.updateIDObject(beneficiary.IDNoImage);
      }
      if (beneficiary.BirthCertImage) {
        if (beneficiary.BirthCertImage.stringValue && beneficiary.BirthCertImage.stringValue.length > 0) {
          form.get('BirthCertImage')?.setValue(beneficiary.BirthCertImage.stringValue);
        }
        this.updateIDObject(beneficiary.BirthCertImage);
      }
      if (beneficiary.RegCertImage) {
        if (beneficiary.RegCertImage.stringValue && beneficiary.RegCertImage.stringValue.length > 0) {
          form.get('RegCertImage')?.setValue(beneficiary.RegCertImage.stringValue);
        }
        this.updateIDObject(beneficiary.RegCertImage);
      }

      if (beneficiary.done == false) {
        if (beneficiary.personalDone == true) {
          if (beneficiary.contactsDone == true) {
            this.pushDocs = true;

          } else {
            this.pushContactInfo = true;
          }
        }
      }

      if (beneficiary.Category === 'Individual') {
        form.get('FirstName')?.addValidators(Validators.required);
        form.get('LastName')?.addValidators(Validators.required);
        form.get('Gender')?.addValidators(Validators.required);
        form.get('DOB')?.addValidators(Validators.required);

        if (this.pushDocs) {
          if (this.getDateDifference(beneficiary.DOB)) {
            form.get('IDNo')?.addValidators([Validators.required, createIDsValidatorEstate('ID', this.getBens(), this.beneficiaries)]);
            form.get('KraPin')?.addValidators(
              [Validators.required, Validators.pattern(/^[AP]\d{9}[A-Z]$/), createIDsValidatorEstate('PIN', this.getBens(), this.beneficiaries)]
            );

            form.get('IDNoImage')?.addValidators(Validators.required);
            form.get('KraPinImage')?.addValidators(Validators.required);

          } else {
            form.get('BirthCertImage')?.addValidators(Validators.required);
            form.get('BirthCertNo')?.addValidators(Validators.required);
          }


        }

      } else {
        form.get('InstitutionName')?.addValidators(Validators.required);
        form.get('BusinessNature')?.addValidators(Validators.required);
        form.get('OrgRegNum')?.addValidators(Validators.required);
        form.get('OrgAddress')?.addValidators(Validators.required);
        form.get('KraPin')?.addValidators(Validators.required);

        if (this.pushDocs) {
          form.get('RegCertImage')?.addValidators(Validators.required);
          form.get('KraPinImage')?.addValidators(Validators.required);
        }
      }

    } else {
      this.pushDocs = false;
      this.pushContactInfo = false;

      if (
        this.beneficiaries && this.beneficiaries.length > 0 &&
        !(this.beneficiaries[0].personalDone && this.beneficiaries[0].personalDone == true)
      ) {
          this.tabControlService.refreshRecord(3);
      }
      
      let currentBens = this.tabControlService.beneficiaries;
      let currentRecord = {personalDone: false, done: false, contactsDone: false};

      if (
        (this.benForm && this.getBens().length > 0) ||
        this.beneficiaries &&
        (this.beneficiaries.length > 0 &&
          !(this.beneficiaries[0].personalDone && this.beneficiaries[0].personalDone == true) ) ||
        this.beneficiaries.length == 0
      ) {
        currentBens.push(currentRecord);
        this.tabControlService.updateRecord(3, currentBens);
      }
      
      form = this._formBuilder.group({
          Category: [this.BeneficiaryOptions[0], Validators.required],
          TrustPurpose: [, Validators.required],
          OtherPurpose: [''],
          Title: [''],
          FirstName: ['', Validators.required],
          MiddleName: [''],
          LastName: ['', Validators.required],
          Gender: ['', Validators.required],
          Phone: [''],
          Email: ['', Validators.email],
          DOB: ['', Validators.required],
          PercShare: ['', [Validators.required, Validators.max(this.finalRemainingShares)]],
    
          IDNo: [''],
          KraPin: [''],
          IDNoImage: [''],

          BirthCertNo: [''],
          BirthCertImage: [''],
    
          InstitutionName: [''],
          BusinessNature: [''],
          OrgRegNum: [''],
          OrgAddress: [''],
    
          ContactPersons: this._formBuilder.array([
            this.createContactsGroup()
          ]),
    
          RegCertImage: [''],
          KraPinImage: ['']
      });
    }

    if (this.benForm && this.getBens().length > 0) {
      this.validationService.addOrRemoveOneValidator(
        true,
        form.get('Phone')!,
        createIDsValidator('TEL', this.getBens(), this.beneficiaries)
      );

      this.validationService.addOrRemoveSpecificValidators(
        true,
        form.get('Email')!,
        [ Validators.email,
          createIDsValidator('EMAIL', this.getBens(), this.beneficiaries)
        ]
      );
    }

    form.get('OtherPurpose')?.valueChanges.subscribe((data) => {
      if (this.arrayContainsValue(data, 'Other'))
        this.validationService.addOrRemoveValidator(true, form.get('OtherPurpose')!);
      else
        this.validationService.addOrRemoveValidator(false, form.get('OtherPurpose')!);
    });

    return form;
  };

  createContactsGroup(contact: any = null): FormGroup {
    let contactForm: FormGroup;
    if (contact) {
      contactForm = this._formBuilder.group({
        Title: [`${contact.Title || ''}`],
        FirstName: [`${contact.FirstName || ''}`],
        MiddleName: [`${contact.MiddleName || ''}`],
        LastName: [`${contact.LastName || ''}`],
        Phone: [`${contact.Phone || ''}`],
        Email: [`${contact.Email || ''}`, Validators.email]
      });

    } else {
      contactForm = this._formBuilder.group({
        Title: [''],
        FirstName: [''],
        MiddleName: [''],
        LastName: [''],
        Phone: [''],
        Email: ['', Validators.email]
      });
    }

    try {
      if (this.benForm && this.getContacts(this.getBens().length-1) && this.getContacts(this.getBens().length-1).length > 0) {
        this.validationService.addOrRemoveOneValidator(
          true,
          contactForm.get('Phone')!,
          createIDsValidator('TEL', this.getBens(), this.beneficiaries)
        );
        console.log('contact updated');
      }
    } catch (error) {
      console.log('Contacts not ready');
    }
    
    
    return contactForm;
  };

  async addNewBen() {
    const fields = this.benForm.get('beneficiaries') as FormArray;
    fields.push(this.createBensFormGroup());
    this.benForm.setControl('beneficiaries', fields);
    this.currentRecordSaved = false;
  }
  async addNewContact(index:number) {
    const fields = this.getContacts(index);
    fields.push(this.createContactsGroup());
    this.getBensFormGroup(index).setControl('ContactPersons', fields);
  }
  
  async removeBen(i:number) {
    this.currentIndex = i;
    const fields = this.benForm.get('beneficiaries') as FormArray;
    let currentBens = this.tabControlService.beneficiaries;

    if (i == currentBens.length-1) {
      this.pushContactInfo = false;
      this.pushDocs = false;
    }

    if (currentBens[i] && currentBens[i].BeneficiaryID) {
      console.log ('Beneficiary ID to remove : ', currentBens[i].BeneficiaryID);
      this.removeButtonText = 'Removing Beneficiary';
      this.loadingRemove = true;

      try {
        if (this.tabControlService.TrustID !== null) {
          const result = await this.tabControlService.removeRecord(
            'Beneficiaries',
            currentBens[i].BeneficiaryID,
            parseInt(this.tabControlService.TrustID));
  
          if (result === 1) {
            this.notificationService.viewToast('success', 'Beneficiary removed successfully');
            if (!isNaN(Number(currentBens[i].PercShare))) {
              this.tabControlService.revertRemainingShares(parseInt(currentBens[i].PercShare));
              this.tabControlService.updateFinalRemainingShares(this.tabControlService.sharesRemaining);
            }

            fields.removeAt(i);
  
            currentBens.splice(i, 1);;
              this.tabControlService.updateRecord(3, currentBens);
          } else {
            this.notificationService.viewToast('error', 'Error removing record');
          }
        } else {
          console.log('Relevant IDs not found');
        }
      } catch (error) {
        console.log(':: Error !! ', error);
      }
      this.removeButtonText = this.removeBenText;
      this.loadingRemove = false;
    } else {
      if (currentBens[i]) {
        if (!isNaN(Number(currentBens[i].PercShare)) && currentBens[i].done) {
          this.tabControlService.revertRemainingShares(parseInt(currentBens[i].PercShare));
          this.tabControlService.updateFinalRemainingShares(this.tabControlService.sharesRemaining);
        }

        fields.removeAt(i);
        // Remove settlor at index
        currentBens.splice(i, 1);;
        this.tabControlService.updateRecord(3, currentBens);
      } else {
        fields.removeAt(i);
      }
    }

    if (this.tabControlService.beneficiaries[this.tabControlService.beneficiaries.length-1].done) {
      this.currentRecordSaved = true;
    }
    this.updateValidity();
  }
  async removeContact(i:number, j: number) {
    const fields = this.getContacts(i);
    fields.removeAt(j);
    this.updateValidity();
  }

  getBens() : FormArray {  
    return this.benForm.get("beneficiaries") as FormArray;
  }
  getContacts(index: number) : FormArray {
    return this.getBens().at(index).get('ContactPersons') as FormArray;  
  }
  getBensFormGroup(index: number): FormGroup {
    return this.getBens().at(index) as FormGroup;
  }
  getContactsFormGroup(index: number, innerdex: number): FormGroup {
    return this.getContacts(index).at(innerdex) as FormGroup;
  }

  async saveBeneficiary(index: number, record: FormGroup, editingShares: boolean = false): Promise<void> {
    if (this.pushDocs || this.pushContactInfo) this.loadingText = 'Updating Beneficiary';
    else this.loadingText = 'Saving Beneficiary';

    let tsMessage = this.addText;

    this.loading = true;
    this.notificationService.isLoading(true);
    
    try {
      let temp = record.value;

      const originalPurpose = temp.TrustPurpose;
      let originalContacts;
      let purpose = this.organizeValues(originalPurpose);

      if (temp.OtherPurpose &&
        temp.OtherPurpose.length > 0) {
        purpose = purpose + ': ' +temp.OtherPurpose;

        
      }
      
      temp.TrustPurpose = purpose;

      if (record.value.Category.toLowerCase() === 'individual') {
        temp.DOB = formatDate(record.value.DOB);
        temp.ContactPersons = [];
      }

      if (editingShares) {
        temp.PercShare = (parseInt(temp.PercShare) + this.finalRemainingShares).toString();
        this.tabControlService.updateRemainingShares(this.finalRemainingShares);
        // console.log('Shares updated : ', temp.PercShare);
      }

      let data = {...temp, ...{TrustID: this.tabControlService.TrustID?.toString()}};

      if (this.pushDocs) {
        originalContacts = data.ContactPersons;
        data.ContactPersons = [];
      }

      if (!(data.IDNoImage === '' || data.IDNoImage === null)) {
        data.IDNoImage = this.idObject;
      } else {
        data.IDNoImage = {name: '', stringValue: ''};
      }

      if (!(data.KraPinImage === '' || data.KraPinImage === null)) {
        data.KraPinImage = this.kraObject;
      } else {
        data.KraPinImage = {name: '', stringValue: ''};
      }

      if (!(data.RegCertImage === '' || data.RegCertImage === null)) {
        data.RegCertImage = this.regCertObject;
      } else {
        data.RegCertImage = {name: '', stringValue: ''};
      }
      if (!(data.BirthCertImage === '' || data.BirthCertImage === null)) {
        data.BirthCertImage = this.birthCertObject;
      } else {
        data.BirthCertImage = {name: '', stringValue: ''};
      }

      let currentBens = this.tabControlService.beneficiaries;
      if (currentBens[index].BeneficiaryID) {
        data = {...data, ...{BeneficiaryID: currentBens[index].BeneficiaryID}};
      }

      const response = await this.apiService.postRequest(
        environment.baseUrl + Constants.saveBenURL, data);
      // console.log('::::: RESPONSE\n', response);
      if (response.Status === 1) {  
        let currentBens = this.beneficiaries;

        const latestRecord = currentBens[index];
        const updatedRecord = {
          ...latestRecord,
          ...record.value,
          ...{TrustPurpose: originalPurpose, BeneficiaryID: response.BeneficiaryID}
        };
        Object.assign(currentBens[index], updatedRecord);

        this.tabControlService.updateRecord(3, currentBens);
        currentBens = this.beneficiaries;
        let currentRecord = currentBens[index];

        if (this.pushDocs) {
          tsMessage = this.updateText;
          
          if (record.value.Category.toLowerCase() === 'individual') {
            currentRecord.ContactPersons = originalContacts;    
          }

          if (data.IDNoImage && data.IDNoImage.stringValue.length > 5 && response.IdURL) {
            currentRecord.IDNoImage = this.idObject;
            currentRecord = {...currentRecord, ...{IDURL: response.IdURL}};
            this.tabControlService.updateDocSummary(response.IdURL);
          }
          if (data.KraPinImage && data.KraPinImage.stringValue.length > 5 && response.KraURL) {
            currentRecord.KraPinImage = this.kraObject;
            currentRecord = {...currentRecord, ...{KraURL: response.KraURL}};
            this.tabControlService.updateDocSummary(response.KraURL);
          }
          if (data.BirthCertImage && data.BirthCertImage.stringValue.length > 5 && response.BirthCertURL) {
            currentRecord.BirthCertImage = this.birthCertObject;
            currentRecord = {...currentRecord, ...{BirthCertURL: response.BirthCertURL}};
            this.tabControlService.updateDocSummary(response.BirthCertURL);
          }
          if (data.RegCertImage && data.RegCertImage.stringValue.length > 5 && response.RegCertURL) {
            currentRecord.RegCertImage = this.regCertObject;
            currentRecord = {...currentRecord, ...{RegCertURL: response.RegCertURL}};
            this.tabControlService.updateDocSummary(response.RegCertURL);
          }
          currentRecord.done = true;
          Object.assign(currentBens[index], currentRecord);

          this.tabControlService.updateRecord(3, currentBens);

          this.pushDocs = false;
          this.currentRecordSaved = true;
          
          // update shares tracker
          this.tabControlService.updateFinalRemainingShares(this.tabControlService.sharesRemaining);

        } else {
          if (record.value.Category.toLowerCase() === 'individual') {
            currentRecord.personalDone = true;
            currentRecord.contactsDone = true;
            // currentRecord = {...currentRecord, ...{verified: true, done: false}};
            Object.assign(currentBens[index], currentRecord);

            this.tabControlService.updateRecord(3, currentBens);
            this.pushDocs = true;

            if (this.getDateDifference(record.value.DOB)) {
              this.validationService.addOrRemoveValidator(
                true,
                this.getBensFormGroup(index).get('IDNoImage')!);

              this.validationService.addOrRemoveSpecificValidators(
                true,
                this.getBensFormGroup(index).get('IDNo')!,
                [Validators.required, createIDsValidatorEstate('ID', this.getBens(), this.beneficiaries)]
              );

              this.validationService.addOrRemove3Validators(
                true,
                this.getBensFormGroup(index).get('KraPin')!,
                [Validators.required, Validators.pattern(/^[AP]\d{9}[A-Z]$/), createIDsValidatorEstate('PIN', this.getBens(), this.beneficiaries)]
              );

            } else {
              this.validationService.addOrRemoveValidator(
                true,
                this.getBensFormGroup(index).get('BirthCertImage')!);
            }

            if (this.beneficiaries.length <= 1) this.tabControlService.updateTrustProgress('Beneficiaries');

          } else {
            if (this.pushContactInfo) {
              currentRecord.contactsDone = true;
              // currentRecord = {...currentRecord, ...{verified: true, done: false}};
              Object.assign(currentBens[index], currentRecord);

              this.tabControlService.updateRecord(3, currentBens);
              this.pushContactInfo = false;
              this.pushDocs = true;

              this.validationService.addOrRemoveValidator(true,
                this.getBensFormGroup(index).get('RegCertImage')!);

              this.validationService.addOrRemoveValidator(true,
                this.getBensFormGroup(index).get('KraPinImage')!);
              

            } else {
              let currentBens = this.beneficiaries;
              let currentRecord = currentBens[index];
              currentRecord.personalDone = true;
              // currentRecord = {...currentRecord, ...{verified: true, done: false}};
              Object.assign(currentBens[index], currentRecord);

              this.tabControlService.updateRecord(3, currentBens);

              this.pushContactInfo = true;

              this.validationService.addOrRemoveValidator(
                true,
                this.getContactsFormGroup(
                  index,
                  this.getContacts(index).length-1).get('Phone')!
                );

              this.validationService.addOrRemoveSpecificValidators(
                true,
                this.getContactsFormGroup(
                  index,
                  this.getContacts(index).length-1).get('Email')!,
                  [Validators.required, Validators.email]
                );

              if (this.beneficiaries.length <= 1) this.tabControlService.updateTrustProgress('Beneficiaries');
            }
          }
          if (!environment.production) console.log('>> New Beneficiaries !! \n', this.tabControlService.beneficiaries);

          this.updateValidity();
        }
        this.notificationService.viewToast('success', tsMessage);

      } else {
        console.log('>>> Error !! ', response.Message);
        this.notificationService.viewToast('error', response.Message);
      }
    } catch (error) {
      console.log(':: Error !! ', error);
      this.notificationService.viewToast('error', 'An unexpected error occurred');
    }
    this.notificationService.isLoading(false);
    this.loading = false;
  }

  async updateProgress(): Promise<void> {
    const bens = this.benForm.get('beneficiaries') as FormArray;
    await this.saveBeneficiary(bens.length - 1, this.getBensFormGroup(bens.length - 1));
    this.saveProgress();
  }

  awardShares(index: number): void {
    this.pushDocs = true;
    this.saveBeneficiary(index, this.getBensFormGroup(index), true);
  }

  submitForm(): void {
    if (this.beneficiaries[this.beneficiaries.length-1].done === true) {
      if (this.finalRemainingShares > 0) {
        this.notificationService.viewToast(
          'info',
          `You have not allocated ${this.finalRemainingShares} shares`,
          'Please assign all shares before proceeding' );
      } else {
        this.navigateFunction();
      }
      
    } else {
      const bens = this.benForm.get('beneficiaries') as FormArray;
      this.saveBeneficiary(bens.length - 1, this.getBensFormGroup(bens.length - 1));
    }
  }

  async navigateFunction(): Promise<void> {
    await this.tabControlService.changeTab(4);
    this.tabControlService.page3Done = true;
  }
}
