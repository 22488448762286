import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TabControlService } from 'src/app/services/tab-control.service';
import { ValidationService } from 'src/app/services/validators/validation.service';
import { environment } from 'src/environments/environment';
import * as Constants from "../../../../constants/constants";
import { ApiService } from 'src/app/services/api/api.service';
import { OtpPopupComponent } from 'src/app/components/otp-popup/otp-popup.component';
import { checkTime, organizeValues } from 'src/app/util/Helper';
import { createIDsValidator } from 'src/app/services/validators/custom.validators';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-step4-guardian',
  templateUrl: './step4-guardian.component.html',
  styleUrls: ['./step4-guardian.component.scss'],
  animations: [
    trigger('bounceIn', [
      state('in', style({ opacity: 1, transform: 'scale(1)' })),
      transition('void => *', [
        style({ opacity: 0, transform: 'scale(0.8)' }),
        animate('0.15s ease-in-out')
      ]),
      transition('* => void', [
        animate('0.15s ease-in-out', style({ opacity: 0, transform: 'scale(0.7)' }))
      ])
    ]),
    trigger('slideInRight', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('0.15s ease-in-out', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('0.15s ease-in-out', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class Step4GuardianComponent {
  @Output() scrollUp = new EventEmitter<number>();
  @ViewChild('suggestBox', { static: false }) suggestBox?: ElementRef;

  checkerPath: string = '../../../../assets/fi_check.png';

  title: string = 'Add Guardians';
  subtitle: string = 'Add one or more guardians below';

  guardianTypeLabel: string = 'Guardian Type';
  guardianTypeHint: string = 'Select guardian type';

  titleLabel: string = 'Title';
  titleHint: string = 'Title';
  fNameLabel: string = 'First Name';
  fNameHint : string = 'First name';
  mNameLabel : string = 'Middle Name';
  mNameHint : string = 'Middle name';
  surnameLabel : string = 'Surname';
  surnameHint : string = 'Last name';
  phoneLabel : string = 'Phone Number';
  emailLabel : string = 'Email Address';
  emailHint : string = 'Email address';

  idLabel: string = 'ID/Passport Number';
  idHint: string = 'Enter ID or Passport Number';
  kraPinLabel: string = 'KRA PIN';
  kraPinHint: string = 'Enter KRA PIN';
  idFileLabel: string = 'Upload copy of ID/Passport';
  kraFileLabel: string = 'Upload KRA PIN';

  bensLabel: string = 'Select Beneficiaries';
  bensHint: string = 'Select all beneficiaries applicable';

  removeGuardianText: string = 'Remove Guardian';
  addButtonText: string = 'Add Another Guardian';

  requiredFieldString: string = 'Required field';
  invalidDocString: string = 'Please attach a valid document';

  currentRecord: any;
  currentIndex: number = 0;
  BeneficiaryList: any[] = [];
  GuardianList: any[] = [];

  loading: boolean = false;
  loadingText: string = 'Saving Guardian';

  buttonText: string = 'Save details';
  buttonDisabled: boolean = false;

  loadingRemove: boolean = false;
  removeButtonText: string = this.removeGuardianText;

  pushDocs: boolean = false;

  addText: string = 'Guardian details saved';
  updateText: string = 'Guardian details updated';

  showSuggestHint: boolean = false;
  showAssignPopup: boolean = false;

  formErrors: { [key: string]: string } = {
    Title: '',
    GuardianBens: '',
    FirstName: '',
    MiddleName: '',
    LastName: '',
    Phone: '',
    Email: '',
    IDNo: '',
    KraPin: '',
    IDNoImage: '',
    KraPinImage: '',
    Category: '',
  };
  validationMessages: { [key: string]: {} } = {
    Title: { required: this.requiredFieldString },
    GuardianBens: { required: this.requiredFieldString },
    FirstName: { required: this.requiredFieldString },
    MiddleName: { required: this.requiredFieldString },
    LastName: { required: this.requiredFieldString },
    Phone: { required: this.requiredFieldString, invalid:'Invalid phone number', exist: 'This number is tied to an existing guardian' },
    Email: { required: this.requiredFieldString, email: "Invalid email address", exist: 'This email is tied to an existing guardian' },
    IDNo: { required: this.requiredFieldString, min:'Invalid ID or Passport number', exist: 'This ID is tied to an existing guardian' },
    KraPin: { required: this.requiredFieldString, pattern:'Invalid KRA PIN', exist: 'This PIN is tied to an existing guardian' },
    IdNoImage: { required: this.requiredFieldString },
    KraPinImage: { required: this.requiredFieldString },
    Category: { required: this.requiredFieldString }
  };

  guardianForm = this._formBuilder.group({
    guardians: this._formBuilder.array([
      this.createGuardiansFormGroup()
    ]),
  });

  constructor(
    private _formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private tabControlService: TabControlService,
    private validationService: ValidationService,
    private apiService: ApiService
  ) {
  }

  ngOnInit(): void {
    if (this.guardians && this.guardians.length > 0) {
      if (this.guardians[0].verified && this.guardians[0].verified == true) {
        const guardiansArray = this.guardianForm.get('guardians') as FormArray;

        // Clear existing settlors
        while (guardiansArray.length !== 0) {
          guardiansArray.removeAt(0);
        }

        // Add beneficiaries from storage
        this.guardians.forEach((guardian: any) => {
          guardiansArray.push(this.createGuardiansFormGroup(false, guardian));
        });

        this.tabControlService.guardians = this.guardians;
      }
    }

    if (this.tabControlService.activeTab == 4) {
      this.populateData();
      
      this.BeneficiaryList = organizeValues(this.beneficiaries, 'Beneficiary');
      // this.GuardianList = organizeValues(this.guardians, 'Guardian');
      // this.showAssignPopup = true;

    }
    if (this.tabControlService.guardians && this.tabControlService.guardians.length > 0) {
      this.currentRecord = this.tabControlService.guardians[this.tabControlService.guardians.length-1]
    }
    this.updateValidity();
    this.guardianForm.valueChanges.subscribe((form) => {
      this.logErrors();
      this.updateValidity();
    });
  }

  logErrors() {
    this.formErrors = this.validationService.logValidationErrors(
      this.guardianForm,
      this.formErrors,
      this.validationMessages
    );
  }

  idObject: any = null;
  kraObject: any = null;
  suggestions: any = null;
  autofilled: boolean = false;

  get settlors(): any {
    return this.tabControlService.settlors;
  }

  get beneficiaries(): any {
    return this.tabControlService.beneficiaries;
  }

  get GuardianOptions() {
    return this.tabControlService.GuardianOptions;
  };

  get guardians(): any {
    return this.tabControlService.guardians;
  }

  get loadingTitles(): boolean {
    return this.tabControlService.loadingTitles;
  }
  get TitleOptions(): any {
    return this.tabControlService.TitleOptions;
  }

  get saveProgress(): ()=> void {
    return this.tabControlService.saveProgress;
  }

  updateValidity() {
    if (this.guardianForm.valid) {
      if (this.loading){ this.buttonDisabled = true;}
      else {this.buttonDisabled = false;}
    } else {
      this.buttonDisabled = true;
    }
    
  };

  async populateData(): Promise<void> {
    if (this.TitleOptions.length === 0) {
      this.tabControlService.loadingTitles = true;
      await this.tabControlService.getTitlesList();
      this.tabControlService.loadingTitles = false;
    }
  }

  updateIDObject(object: any): void {
    this.idObject = object;
    // console.log('ID doc updated: ', this.idObject.name);
  }
  updateKraObject(object: any): void {
    this.kraObject = object;
  }

  briefTimeout: any;
  showSuggestBrief(): void {
    setTimeout(() => {
      this.showSuggestHint = true;
    }, 1000);

    this.briefTimeout = setTimeout(() => {
      this.showSuggestHint = false;
    }, 30000);
  }

  async suggestSettlors(type: 'ID' | 'NAME' , val: string): Promise<any> {
    if (this.autofilled) {
      this.resetForm();
    }
    
    this.suggestions = null;
    let validEntries: any = [];

    if (val.length > 2) {
        try {
            const fields = this.guardianForm.get('guardians') as FormArray;

            for (let i = 0; i < this.settlors.length; i++) {
                let exist = false;
                let trueStuff = this.settlors[i].FirstName.includes(val);

                if (type === 'ID') {
                  trueStuff = this.settlors[i].IDNo.includes(val);
                }

                if (trueStuff) {
                    for (let j = 0; j < fields.length - 1; j++) {
                        if (
                            fields.at(j).get('IDNo')?.value === this.settlors[i].IDNo ||
                            fields.at(j).get('Phone')?.value === this.settlors[i].Phone ||
                            fields.at(j).get('KraPin')?.value === this.settlors[i].KraPin) {
                            exist = true;
                        } else {
                          this.showSuggestHint = false;
                          clearTimeout(this.briefTimeout);
                        }
                    }

                    if (!exist && validEntries.length < 3 && !validEntries.some((entry: { IDNo: string; }) => entry.IDNo === this.settlors[i].IDNo)) {
                        validEntries.push(this.settlors[i]);
                    }
                }
            }

            if (validEntries.length > 0) {
              this.suggestions = validEntries;
            }

            if (this.suggestions !== null) {
              const childDivHeight = this.suggestBox?.nativeElement.clientHeight;
              this.scrollUp.emit(childDivHeight);
            }
        } catch (error) {
            console.log('ERR !!! ', error);
        }
    }

    return this.suggestions;
  }

  resetForm(): void {
    try {
      this.autofilled = false;
      this.pushDocs = false;

      let currentGuardians = this.tabControlService.guardians;
      const latestRecord = currentGuardians[currentGuardians.length - 1];
      latestRecord.verified = false;

      Object.assign(currentGuardians[currentGuardians.length - 1], latestRecord);
      this.tabControlService.updateRecord(4, currentGuardians);

      const guradian = this.getGuardians().at(this.getGuardians().length-1) as FormGroup;
      guradian.get('Title')?.setValue('');
      guradian.get('FirstName')?.setValue('');
      guradian.get('MiddleName')?.setValue('');
      guradian.get('LastName')?.setValue('');
      guradian.get('Phone')?.setValue('7');
      guradian.get('Email')?.setValue('');
      guradian.get('IDNo')?.setValue('');
      guradian.get('KraPin')?.setValue('');
      guradian.get('IDNoImage')?.setValue(null);
      guradian.get('KraPinImage')?.setValue(null);

      this.updateIDObject(null);
      this.updateKraObject(null);

      this.validationService.addOrRemoveValidator(false, guradian.get('IDNoImage')!);
      this.validationService.addOrRemoveValidator(false, guradian.get('KraPinImage')!);

    } catch (error) {
      console.log('Error!! ', error);
    }
  }


  patchSuggestedValues(index: number, value: any): void {
    try {
      if (value) {
        const fields = this.guardianForm.get('guardians') as FormArray;
        fields.removeAt(index);
        fields.push(this.createGuardiansFormGroup(true, value));

        const guradian = fields.at(index) as FormGroup;

        this.validationService.addOrRemoveValidator(true, guradian.get('IDNoImage')!);
        this.validationService.addOrRemoveValidator(true, guradian.get('KraPinImage')!);

        // console.log('Guradian \n', guradian);
      //  fields.setControl(index, guradian);
        this.guardianForm.setControl('guardians', fields);
        
        this.suggestions = null;
        this.autofilled = true;
        this.pushDocs = true;
      }
      this.updateValidity();

    } catch (error) {
      console.log('ERR ??? ', error);
    }
  }

  createGuardiansFormGroup(patch: boolean=false, patchData: any=null): FormGroup {
    let currentGuardians = this.tabControlService.guardians;
    let form: FormGroup;
    
    let category = this.tabControlService.GuardianOptions[0];

    if (this.guardianForm && this.getGuardians().length > 0) {
      category = this.tabControlService.GuardianOptions[1];
    }

    if (patchData) {
      if (patch) {
        this.autofilled = true;
        this.pushDocs = true;
        

        form = this._formBuilder.group({
          Title: [patchData.Title || ''],
          Category: [category , Validators.required],
          GuardianBens: [, Validators.required],
          FirstName: [patchData.FirstName , Validators.required],
          MiddleName: [patchData.MiddleName || ''],
          LastName: [patchData.LastName, Validators.required],
          Phone: [patchData.Phone, Validators.required],
          Email: [patchData.Email, [Validators.required, Validators.email]],
          IDNo: [patchData.IDNo, [Validators.required, Validators.min(4)]],
          KraPin: [patchData.KraPin, [Validators.required, Validators.pattern(/^[AP]\d{9}[A-Z]$/) ]],
          IDNoImage: [],
          KraPinImage: [],
        });

        const latestRecord = currentGuardians[currentGuardians.length - 1];
        latestRecord.verified = true; // Helpful in repeating values validations
        Object.assign(currentGuardians[currentGuardians.length - 1], latestRecord);
        this.tabControlService.updateRecord(4, currentGuardians);

      } else {
        form = this._formBuilder.group({
          Title: [patchData.Title || ''],
          Category: [patchData.Category || category , Validators.required],
          GuardianBens: [, Validators.required],
          FirstName: [patchData.FirstName , Validators.required],
          MiddleName: [patchData.MiddleName || ''],
          LastName: [patchData.LastName || '', Validators.required],
          Phone: [patchData.Phone || '', Validators.required],
          Email: [patchData.Email || '', [Validators.required, Validators.email]],
          IDNo: [patchData.IDNo || '', [Validators.required, Validators.min(4)]],
          KraPin: [patchData.KraPin || '', [Validators.required, Validators.pattern(/^[AP]\d{9}[A-Z]$/) ]],
          IDNoImage: [],
          KraPinImage: [],
        });

        if (patchData.GuardianBens && patchData.GuardianBens.length > 0) {
          let items = [];

          for (let i=0; i< patchData.GuardianBens.length; i++) {
            if (patchData.GuardianBens[i].BeneficiaryID && patchData.GuardianBens[i].BeneficiaryID !== '')
              items.push(Number(patchData.GuardianBens[i].BeneficiaryID));
          }

          form.get('GuardianBens')?.setValue(items);
        }

        if (patchData.done == false && patchData.verified == true) {
          this.pushDocs = true;
          
          this.validationService.addOrRemoveValidator(true, form.get('IDNoImage')!);
          this.validationService.addOrRemoveValidator(true, form.get('KraPinImage')!);
        }
      }

      if (patchData.IDNoImage) {
        form.get('IDNoImage')?.setValue(patchData.IDNoImage.stringValue);
        this.updateIDObject(patchData.IDNoImage);
        // console.log('New ID :\n', this.idObject);
      }

      if (patchData.KraPinImage) {
        form.get('KraPinImage')?.setValue(patchData.KraPinImage.stringValue);
        this.updateKraObject(patchData.KraPinImage);
        // console.log('New KRA PIN :\n', this.kraObject);
      }

    } else {
      this.showSuggestBrief();

      this.autofilled = false;
      this.pushDocs = false;

      let currentRecord: any = {verified: false, done: false};


      if (
        this.guardians && this.guardians.length > 0 &&
        !(this.guardians[0].verified && this.guardians[0].verified == true)
      ) {
        currentRecord = {verified: false, done: false, Category: 'Principal Guardian'};
        this.tabControlService.refreshRecord(4);
        currentGuardians = this.tabControlService.guardians;
      }


      if ((this.guardianForm && this.getGuardians().length > 0) ||
        this.guardians &&
          (this.guardians.length > 0 &&
        !(this.guardians[0].verified && this.guardians[0].verified == true)) ||
        this.guardians.length == 0
      ) {
        currentGuardians.push(currentRecord);
        this.tabControlService.updateRecord(4, currentGuardians);
      }
      
      form = this._formBuilder.group({
        Title: [''],
        Category: [category , Validators.required],
        GuardianBens: [, Validators.required],
        FirstName: ['', Validators.required],
        MiddleName: [''],
        LastName: ['', Validators.required],
        Phone: ['', Validators.required],
        Email: ['', [Validators.required, Validators.email]],
        IDNo: ['', [Validators.required, Validators.min(4)]],
        KraPin: ['', [Validators.required, Validators.pattern(/^[AP]\d{9}[A-Z]$/) ]],
        IDNoImage: [],
        KraPinImage: [],
      });
    }
      
    if (this.guardianForm && this.getGuardians().length > 0) {
      this.validationService.addOrRemove3Validators(
        true,
        form.get('Email')!,
        [Validators.required,
          Validators.email,
          createIDsValidator('EMAIL', this.getGuardians(), this.guardians)
        ]
      );
      this.validationService.addOrRemoveSpecificValidators(
        true,
        form.get('Phone')!,
        [Validators.required,
          createIDsValidator('TEL', this.getGuardians(), this.guardians)
        ]
      );
      this.validationService.addOrRemove3Validators(
        true,
        form.get('IDNo')!,
        [Validators.required,
          Validators.min(4),
          createIDsValidator('ID', this.getGuardians(), this.guardians)
        ]
      );
      this.validationService.addOrRemove3Validators(
        true,
        form.get('KraPin')!,
        [Validators.required,
          Validators.pattern(/^[AP]\d{9}[A-Z]$/),
          createIDsValidator('PIN', this.getGuardians(), this.guardians)
        ]
      );
    }

    return form;
  };

  async addNewGuardian() {
    const fields = this.guardianForm.get('guardians') as FormArray;
    fields.push(this.createGuardiansFormGroup());
    this.guardianForm.setControl('guardians', fields);
    this.updateIDObject(null);
    this.updateKraObject(null);
    this.buttonText = 'Save Details';
  }

  async removeGuardian(i:number) {
    this.currentIndex = i;
    const fields = this.guardianForm.get('guardians') as FormArray;
    let currentGuardians = this.tabControlService.guardians;

    if (currentGuardians[i] && currentGuardians[i].GuardianID) {
      this.removeButtonText = 'Removing Guardian';
      this.loadingRemove = true;

      try {
        const records = this.guardianForm.controls['guardians'].value;
  
        if (this.tabControlService.TrustID !== null) {
          const result = await this.tabControlService.removeRecord(
            'Guardians',
            currentGuardians[i].GuardianID,
            parseInt(this.tabControlService.TrustID));
  
          if (result === 1) {
            this.notificationService.viewToast('success', 'Guardian removed successfully');
            fields.removeAt(i);
            // Remove guardian at index
            currentGuardians.splice(i, 1);;
            this.tabControlService.updateRecord(4, currentGuardians);
          } else {
            this.notificationService.viewToast('error', 'Error removing record');
          }
        } else {
          console.log('Relevant IDs not found');
        }
      } catch (error) {
        console.log(':: Error !! ', error);
      }
      this.removeButtonText = this.removeGuardianText;
      this.buttonText = 'Save details';
      this.loadingRemove = false;
    } else {
      if (currentGuardians[i] && currentGuardians[i].done === false) {
        fields.removeAt(i);
        // Remove settlor at index
        currentGuardians.splice(i, 1);;
        this.tabControlService.updateRecord(4, currentGuardians);
      } else {
        fields.removeAt(i);
      }
      
    };
  }
  
  getGuardians() : FormArray {  
    return this.guardianForm.get("guardians") as FormArray  
  }
  getGuardiansFormGroup(index: number): FormGroup {
    const guardians = this.guardianForm.get('guardians') as FormArray;
    return guardians.at(index) as FormGroup;
  }

  async saveGuardian(): Promise<void> {
    if (this.pushDocs && !this.autofilled) this.loadingText = 'Updating Guardian';
    else this.loadingText = 'Saving Guardian';
    let tsMessage = this.addText;

    this.loading = true;
    this.notificationService.isLoading(true);
    try {
      const guardians = this.guardianForm.get('guardians') as FormArray;
      const record: FormGroup = this.getGuardiansFormGroup(guardians.length - 1) as FormGroup;

      let data;
      
      if (guardians.length === 1) {
        data = {...record.value, ...{TrustID: this.tabControlService.TrustID, Category: 'Principal Guardian'}};
      } else {
        data = {...record.value, ...{TrustID: this.tabControlService.TrustID}};
      }

      let currentGuardians = this.tabControlService.guardians;

      if (this.pushDocs) {
        data.IDNoImage = this.idObject;
        data.KraPinImage = this.kraObject;

        let guardsBens = [];
        for (let i=0; i<data.GuardianBens.length; i++) {
          let id = '';

          if (currentGuardians[currentGuardians.length - 1].GuardianBens
              && currentGuardians[currentGuardians.length - 1].GuardianBens[i]
              && currentGuardians[currentGuardians.length - 1].GuardianBens[i].ID
              && currentGuardians[currentGuardians.length - 1].GuardianBens[i].ID !== ''
              && currentGuardians[currentGuardians.length - 1].GuardianBens[i].BeneficiaryID
              && currentGuardians[currentGuardians.length - 1].GuardianBens[i].BeneficiaryID === data.GuardianBens[i].toString()
            ) {

              id = currentGuardians[currentGuardians.length - 1].GuardianBens[i].ID;
          }

          const item = {
            ID: id.toString(),
            BeneficiaryID: data.GuardianBens[i].toString()
          };

          guardsBens.push(item);
        }
        // Organize for middleware
        data.GuardianBens = guardsBens;
        // console.log('Data ?? ', data);

      } else {
        data.IDNoImage = {name: '', stringValue: ''};
        data.KraPinImage = {name: '', stringValue: ''};

        data.GuardianBens = [];
      }

      if (currentGuardians[currentGuardians.length - 1].GuardianID) {
        data = {...data, ...{GuardianID: currentGuardians[currentGuardians.length - 1].GuardianID}};
      }

      const response = await this.apiService.postRequest(
        environment.baseUrl + Constants.saveGuardianURL, data);

      // console.log('::::: RESPONSE ::::::\n', response);

      if (response.Status === 1) {
        let currentGuardians = this.tabControlService.guardians;
        const latestRecord = currentGuardians[currentGuardians.length - 1];
        const updatedRecord = {...latestRecord, ...record.value, ...{GuardianID: response.GuardianID}};
        Object.assign(currentGuardians[currentGuardians.length - 1], updatedRecord);

        this.tabControlService.updateRecord(4, currentGuardians);

        if (this.pushDocs) {
          tsMessage = this.updateText;
          let currentGuardians = this.tabControlService.guardians;
          let currentRecord = currentGuardians[currentGuardians.length-1];
          
          if (this.autofilled) {
            tsMessage = this.addText;
            currentRecord.verified = true;
          }
          
          currentRecord.IDNoImage = this.idObject;
          currentRecord.KraPinImage = this.kraObject;
          currentRecord.done = true;
          
          if (response.IdURL) {
            this.tabControlService.updateDocSummary(response.IdURL);
            currentRecord = {...currentRecord, ...{IDURL: response.IdURL}};
          }
          if (response.KraURL) {
            this.tabControlService.updateDocSummary(response.KraURL);
            currentRecord = {...currentRecord, ...{KraURL: response.KraURL}};
          }

          if (response.Beneficiaries && response.Beneficiaries.length > 0) {
            currentRecord.GuardianBens = response.Beneficiaries;
          }
          
          Object.assign(currentGuardians[currentGuardians.length - 1], currentRecord);
          this.tabControlService.updateRecord(4, currentGuardians);
          
          this.pushDocs = false;
          this.autofilled = false;
          this.kraObject = null;
          this.idObject = null;

        } else {
          // Update guardian progress
          let currentGuardians = this.tabControlService.guardians;
          let currentRecord = currentGuardians[currentGuardians.length-1];
          currentRecord.verified = true;
          // currentRecord = {...currentRecord, ...{verified: true, done: false}};
          Object.assign(currentGuardians[currentGuardians.length - 1], currentRecord);
          this.tabControlService.updateRecord(4, currentGuardians);

          this.pushDocs = true;
          this.buttonText = 'Upload documents';

          this.validationService.addOrRemoveValidator(true, this.getGuardiansFormGroup(guardians.length - 1).get('IDNoImage')!);
          this.validationService.addOrRemoveValidator(true, this.getGuardiansFormGroup(guardians.length - 1).get('KraPinImage')!);
          this.updateValidity();
          
          if (this.guardians.length <= 1) this.tabControlService.updateTrustProgress('Guardians');
        }

        if (!environment.production) console.log('>> New Guardians !! \n', this.tabControlService.guardians);
        // console.log('>> Current Formgroup !! \n', this.getGuardians());
        this.notificationService.viewToast('success', tsMessage);

      } else {
        this.notificationService.viewToast('error', response.Message);
      }
    } catch (error) {
      console.log(':: Error !! ', error);
      this.notificationService.viewToast('error', 'An unexpected error occured');
    }
    this.notificationService.isLoading(false);
    this.loading = false;
  }

  async updateProgress(): Promise<void> {
    await this.saveGuardian();
    this.saveProgress();
  }

  async submitForm(): Promise<void> {
    // console.log(':: RECORD DONE? !! ', this.guardians[this.guardians.length-1].done);
    if (this.guardians[this.guardians.length-1].done) {
      this.navigateFunction();
    } else {
      this.saveGuardian();
    }
  }

  toggleAssignPopup(value: boolean): void {
    this.showAssignPopup = value;
  }

  async openAssignPopup(): Promise<void> {
    this.toggleAssignPopup(true);
  }

  closeAssignPopup = async() => {
    this.toggleAssignPopup(false);
    await this.tabControlService.changeTab(5);
    this.tabControlService.page4Done = true;
  }

  async navigateFunction(): Promise<void> {
    const unassignedBeneficiaries = this.checkAllBeneficiariesAssigned(this.beneficiaries, this.guardians);

    if (unassignedBeneficiaries.length === 0) {
      await this.tabControlService.changeTab(5);
      this.tabControlService.page4Done = true;
    } else {
      const filteredArray = this.filterBeneficiariesArray(this.beneficiaries, unassignedBeneficiaries);
      this.BeneficiaryList = organizeValues(filteredArray, 'Beneficiary');
      this.GuardianList = organizeValues(this.guardians, 'Guardian');

      this.openAssignPopup();
    }
  }

  checkAllBeneficiariesAssigned(mainArray: any[], secondaryParentArray: any[]): string[] {
    const beneficiaryIDs = mainArray.map(obj => obj.BeneficiaryID.toString()); // Convert BeneficiaryID to string for comparison
    const allSecondaryBeneficiaryIDs: string[] = [];

    // Iterate over each object in the secondaryParentArray
    secondaryParentArray.forEach(parentObj => {
        if (parentObj.GuardianBens && parentObj.GuardianBens.length > 0) {
            const secondaryBeneficiaryIDs = parentObj.GuardianBens.map((obj: { BeneficiaryID: { toString: () => any; }; }) => obj.BeneficiaryID.toString()); // Convert BeneficiaryID to string for comparison
            allSecondaryBeneficiaryIDs.push(...secondaryBeneficiaryIDs);
        }
    });

    // Find missing BeneficiaryIDs from the mainArray in the allSecondaryBeneficiaryIDs
    const missingBeneficiaryIDs = beneficiaryIDs.filter(id => !allSecondaryBeneficiaryIDs.includes(id));
    return missingBeneficiaryIDs;
  }

  filterBeneficiariesArray(mainArray: any[], missingBeneficiaryIDs: string[]): any[] {
    return mainArray.filter(obj => missingBeneficiaryIDs.includes(obj.BeneficiaryID.toString()));
  }

}
