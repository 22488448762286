import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  contentTemplate: any;
  loading = false;
  title: string = '';

  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { contentTemplate: any; modalTitle: string;}
  ) {
    this.contentTemplate = data.contentTemplate;
    this.title = data.modalTitle;
  }

  async onSubmit(): Promise<void> {
    this.dialogRef.close(true); // Indicate success to the caller
  }
}
