import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
} from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
import { TabControlService } from 'src/app/services/tab-control.service';
import * as Constants from "../../../constants/constants";
import { trigger, transition, style, animate, state } from '@angular/animations';
import { BehaviorSubject, interval, Subscription, switchMap } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
  animations: [
    trigger('fade', [
      state('show', style({ opacity: '1' })),
      state('hide', style({ opacity: '0' })),
      transition('show => hide', animate('1s')),
      transition('hide => show', animate('1s')),
    ]),
  ],
})
export class StartComponent implements OnInit, OnDestroy {

  SHOW = 'show';
  HIDE = 'hide';
  VOID = 'void';

  counter: number = 0;
  animating: boolean = true;
  stop: boolean = false;
  intervalId: any;
  private slideshowSubscription!: Subscription;
  
  slideshowImages: any[] = [
    {
      url: '../../../assets/slideshow/image1.png',
      state: this.SHOW,
    },
    {
      url: '../../../assets/slideshow/image2.png',
      state: this.HIDE,
    },
    {
      url: '../../../assets/slideshow/image3.png',
      state: this.HIDE,
    },
    {
      url: '../../../assets/slideshow/image4.png',
      state: this.HIDE,
    },
    {
      url: '../../../assets/slideshow/image5.png',
      state: this.HIDE,
    },
    {
      url: '../../../assets/slideshow/image6.png',
      state: this.HIDE,
    },
    {
      url: '../../../assets/slideshow/image7.png',
      state: this.HIDE,
    },
    {
      url: '../../../assets/slideshow/image8.png',
      // color: '#0064C8',
      state: this.HIDE,
    }
  ];

  mileleTrustURL: string = 'https://icealion.co.ke/milele-trust-2/';
  estatePlanningURL: string = 'https://icealion.co.ke/estate-planning/';
  MileleLogoPath: string  = '../../../assets/Milele_logo_white.png';
  logoPath: string  = '../../../assets/group-logo-white.png';
  forumPath: string  = '../../../assets/forum.png';

  warnPath: string = '../../../assets/warn.png';
  arrowPath: string = '../../../assets/arrow.png';

  videoLink: string = 'https://www.youtube.com/embed/2ioz-j5w4j0';
  sanitizedVideoUrl!: SafeResourceUrl;
  
  // Strings
  mileleTrustFund: string = 'Trust Fund';
  milelePrivateTrust: string = 'Estate Planning';
  legacyProtectText: string = 'You’ve got a legacy to protect';
  legacySubtext: string = 'Create a cash management Trust to protect and provide for you and your loved ones during your lifetime and in the unfortunate event of your demise.';
  secureFutureText: string = 'Secure a future for you and your loved ones';
  secureSubtext: string = 'Decide what’s right for you and your loved ones by creating a customized trust-based estate plan over your property.';
  learnMoreText = 'Learn More';
  takeTestText: string = 'Not sure what’s best? Take a test';
  startHereText: string = 'Start Here';

  needHelpText: string = 'Need more information?';
  bookCallText: string = 'Book A Call';
  brochureText: string = 'Download Brochures';
  existingLoginText: string = 'Existing Client Login';
  chatUsText: string = 'Chat with us. How may we help?';
  powerdByText: string = 'Powered by ICEA LION Group. All rights reserved.';
  
  showBookCall: boolean = false;
  showBrochures: boolean = false;

  showEstateOptions: boolean = false;

  session: string | null = null;
  
  // Form
  startForm = this._formBuilder.group({
  });

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private tabControlService: TabControlService,
    private sanitizer: DomSanitizer
  ) {

  }

  async ngOnInit(): Promise<void> {
    this.showNext();
    this.sanitizedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoLink);
    this.route.queryParams.subscribe(async (params) => {
      // console.log('Params---> ',params); // log route params
      try {
        if (params["session"] != undefined) {
          this.session = params["session"];
          console.log(this.session)
        }
      } catch (error) {
        console.error('Session Resume error! ', error);
      }
    });

    // this.shuffleArray(this.slideshowImages);
    const trustData = await this.tabControlService.getData(this.tabControlService.storageKey);
    if (trustData) {
      this.trustDataHolder = trustData;

      if (trustData.activeTab === 1) this.resumeActiveText = 'Verify Personal Information';
      if (trustData.activeTab === 2) this.resumeActiveText = 'Add Settlor(s)';
      if (trustData.activeTab === 3) this.resumeActiveText = 'Add Beneficiaries';
      if (trustData.activeTab === 4) this.resumeActiveText = 'Add Guardian(s)';
      if (trustData.activeTab === 5) this.resumeActiveText = 'Trust Information';
      if (trustData.activeTab === 6) this.resumeActiveText = 'Preview';
      if (trustData.activeTab === 7) this.resumeActiveText = 'Payment';
    }
  }

  ngOnDestroy() {
    if (this.slideshowSubscription) {
      this.slideshowSubscription.unsubscribe();
    }
  }

  showResumePopup: boolean = false;
  resumingEstate: boolean = false;
  trustDataHolder: any = null;
  resumeActiveText: string = '';

  // Images
  get index() {
    return this.counter % this.slideshowImages.length;
  }
  set index(value: number) {
    this.counter = value;
  }

  showNext() {
    // Start the slideshow animation in a loop using interval
    this.slideshowSubscription = interval(5000).pipe(
      switchMap(() => {
        this.slideshowImages[this.index++].state = this.HIDE;
        this.slideshowImages[this.index].state = this.SHOW;
        return [this.index]; // Emit the updated index (optional)
      })
    ).subscribe();
  }

  onFade(event: any) {
    if (event.fromState === this.VOID) return; // This fires once on init - do nothing
  }
  
  shuffleArray(array: any[]): void {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  viewBrochures(): void {
    this.showBrochures = true;
  }

  openTrustBrochure(): void {
    window.open(`assets/brochures/${Constants.trustFundBrochureName}`, "_blank");
    this.showBrochures = false;
  }

  openFamilyBrochure(): void {
    window.open(`assets/brochures/${Constants.estatePlanningBrochureName}`, "_blank");
    this.showBrochures = false;
  }


  navTrustFundInfo(): void {
    const nextPage = `/info/trust-fund`;
    if (this.session) this.router.navigate([nextPage], {queryParams: {session: this.session}});
    else this.router.navigate([nextPage]);
  }

  navPrivateTrustInfo(): void {
    const nextPage = `/info/estate-planning`;
    if (this.session) this.router.navigate([nextPage], {queryParams: {session: this.session}});
    else this.router.navigate([nextPage]);
  }
  
  checkMileleTrustResume(): void {
    if (this.trustDataHolder !== null) {
      this.resumingEstate = false;
      this.showResumePopup = true;
    } else {
      this.navTrustFund(false);
    }
  }

  async navTrustFund(resume: boolean = false): Promise<void> {
    this.showResumePopup = false;
    if (resume) {
      await this.tabControlService.updateDataFromStorage();
    } else {
      this.tabControlService.resetLocalData();
    }

    const naxPage = `/trust-fund/set-up`;
    if (this.session) this.router.navigate([naxPage], {queryParams: {session: this.session}});
    else this.router.navigate([naxPage]);
  }

  toggleBookPopupClose(value: boolean): void {
    this.showBrochures = false;
    this.showBookCall = value;
  }
  toggleEstatePopupClose(value: boolean): void {
    this.showBrochures = false;
    this.showBookCall = false;
    this.showEstateOptions = value;
  }


}
