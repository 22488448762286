<div [formGroup]="formGroup" class="input-form" >
    <div class="label" [@bounceIn]>
        <p>{{label}}</p>&nbsp;
        <span *ngIf="required === 'true'" style="color: red;">*</span>

        <span class="required-error-upper" *ngIf="subtext !== '' && required === 'true' && !inputTouched && inputType !== 'time' && formControl?.value === '' " >
            Required field
        </span>

        <span class="required-error-upper" *ngIf="inputType == 'time'&& formControl?.value === '' " >
            Required field
        </span>

        <span class="file-types" *ngIf="inputType === 'file'">
            &#8729; {{ 'PDF | JPG' }}
        </span>
    </div>
    
    <mat-form-field *ngIf="inputType === 'normal'"
        appearance="outline" [ngClass]="{'input': inputFocused}" [@fadeIn]>

        <input matInput
            formControlName={{controlName}}
            [placeholder]="hint"
            [type]="type"
            maxlength="{{controlName === 'PercShare' ? '3' : controlName.toLowerCase() === 'distributionage' ? '2' : '100'}}"
            (focus)="onInputFocus()"
            (blur)="onInputBlur()">
        <mat-hint *ngIf="subtext !== ''" class="hints">
            {{subtext}}
        </mat-hint>
        <mat-error *ngIf="formErrors[controlName]" class="errors">
            {{formErrors[controlName]}}
        </mat-error>

        <span class="required-error" *ngIf="subtext === '' && required === 'true' && !inputTouched && formControl?.value === '' " >
            Required field
        </span>
    </mat-form-field>

    <mat-form-field *ngIf="inputType === 'area'"
        appearance="outline" [ngClass]="{'input': inputFocused}" [@fadeIn] style="height: 150px;">

        <textarea matInput formControlName={{controlName}} [placeholder]="hint"
            cdkTextareaAutosize
            cdkAutosizeMinRows="6"
            cdkAutosizeMaxRows="6"
            (focus)="onInputFocus()"
            (blur)="onInputBlur()">
        </textarea>
        <mat-error *ngIf="formErrors[controlName]" class="errors">
            {{formErrors[controlName]}}
        </mat-error>

        
    </mat-form-field>
    <div class="required-error-area" *ngIf="inputType === 'area' && required === 'true' && !inputTouched && formControl?.value === '' " >
        Required field
    </div>

    <mat-form-field *ngIf="inputType === 'select'"
        appearance="outline" [ngClass]="{'input': inputFocused, 'select-loading' : loadingOptions, 'max-160' : isTitle()}" [@fadeIn]>

        <mat-select formControlName={{controlName}} panelClass="form-select-popup"
            [placeholder]="hint"
            (focus)="onInputFocus()"
            (blur)="onInputBlur()"
            (selectionChange)="onSelectionChange()" >
            
            <mat-option class="form-option"
                *ngFor="let arrayItem of ArrayItems"
                [value]="isLocalInput() ? arrayItem :
                    isTitle() ? arrayItem.Title :
                    isCountry() ? arrayItem.CountryName :
                    isCounty() ? arrayItem.CountyName :
                    isNationality() ? arrayItem.Nationality :
                    isBank() || isBankBranch() ? arrayItem.ID :
                    arrayItem.Description "
                >

                {{ isLocalInput() ? arrayItem :
                    isTitle() ? arrayItem.Title :
                    isCountry() ? arrayItem.CountryName :
                    isCounty() ? arrayItem.CountyName :
                    isNationality() ? arrayItem.Nationality :
                    arrayItem.Description
                }}
            </mat-option>
        </mat-select>
        <span matSuffix *ngIf="loadingOptions" class="spinner">
            <mat-progress-spinner mode="indeterminate" diameter="22" strokeWidth="2"></mat-progress-spinner>
        </span>
        <mat-error *ngIf="formErrors[controlName]" class="errors">
            {{formErrors[controlName]}}
        </mat-error>

        <span class="required-error" *ngIf="required === 'true' && !inputTouched && formControl?.value === '' " >
            Required field
        </span>
    </mat-form-field>
 
    <mat-form-field *ngIf="inputType === 'multi-select'"
        appearance="outline" [ngClass]="{'input': inputFocused, 'select-loading' : loadingOptions}" [@fadeIn]>

        <mat-select multiple formControlName={{controlName}} style="resize: none; text-overflow: ellipsis;" panelClass="form-select-popup"
            [placeholder]="hint"
            (focus)="onInputFocus()"
            (blur)="onInputBlur()"
            (selectionChange)="onSelectionChange()" >
            
            <mat-option class="form-option"
                [value]="isLocalInput() ? arrayItem  : controlName === 'GuardianBens' ? arrayItem.ID : arrayItem.Description "
                *ngFor="let arrayItem of ArrayItems">
                
                {{isLocalInput() ? arrayItem : arrayItem.Description}}
            </mat-option>
        </mat-select>
        <span matSuffix *ngIf="loadingOptions" class="spinner">
            <mat-progress-spinner mode="indeterminate" diameter="22" strokeWidth="2"></mat-progress-spinner>
        </span>
        <mat-hint *ngIf="subtext !== ''" class="hints">
            {{subtext}}
        </mat-hint>
        <mat-error *ngIf="formErrors[controlName]" class="errors">
            {{formErrors[controlName]}}
        </mat-error>

        <span class="required-error" *ngIf="required === 'true' && !inputTouched && formControl?.value === '' " >
            Required field
        </span>
    </mat-form-field>

    <mat-form-field *ngIf="inputType === 'auto-complete'"
        appearance="outline" [ngClass]="{'input': inputFocused, 'select-loading' : loadingOptions}" [@fadeIn]>

        <input
           type="text"
           placeholder="Type or select the intermediary"
           matInput
           formControlName={{controlName}}
           [matAutocomplete]="auto"
           (input)="filter()"
           (focus)="onInputFocus()"
           (blur)="onInputBlur()">

        <mat-autocomplete requireSelection #auto="matAutocomplete" panelClass="form-select-popup">
            <mat-option *ngFor="let option of filteredArray; trackBy: trackByFn" [value]="option.Description">
                {{option.Description}}
            </mat-option>
        </mat-autocomplete>

        <span matSuffix *ngIf="loadingOptions" class="spinner">
            <mat-progress-spinner mode="indeterminate" diameter="22" strokeWidth="2"></mat-progress-spinner>
        </span>
        <mat-error *ngIf="formErrors[controlName]" class="errors">
            {{formErrors[controlName]}}
        </mat-error>

        <span class="required-error" *ngIf="required === 'true' && !inputTouched && formControl?.value === '' " >
            Required field
        </span>
    </mat-form-field>

    <mat-form-field *ngIf="inputType === 'date'"
        class="date-input"
        appearance="outline"
        [ngClass]="{'input': inputFocused}"
        [@fadeIn]
      >
        <input
            *ngIf="!weekdaysOnly"
            matInput
            [min]="minDate"
            [max]="maxDate"
            [matDatepicker]="picker"
            readonly="true"
            (click)="picker.open()"
            (focus)="onInputFocus()"
            (blur)="onInputBlur()"
            [placeholder]="hint"
            formControlName={{controlName}} 
            />
        <input
         *ngIf="weekdaysOnly"
          matInput
          [min]="minDate"
          [max]="maxDate"
          [matDatepicker]="picker"
          [matDatepickerFilter]="dateFilter"
          readonly="true"
          (click)="picker.open()"
          (focus)="onInputFocus()"
          (blur)="onInputBlur()"
          [placeholder]="hint"
          formControlName={{controlName}} 
        />
        <mat-datepicker-toggle matIconPrefix [for]="picker">
            <mat-icon matDatepickerToggleIcon style="margin-bottom: 2px !important;">event</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker> </mat-datepicker>
        <mat-error *ngIf="formErrors[controlName]" class="errors">
            {{formErrors[controlName]}}
        </mat-error>

        <span class="required-error" *ngIf="required === 'true' && !inputTouched && formControl?.value === '' " >
            Required field
        </span>
    </mat-form-field>

    <mat-form-field *ngIf="inputType === 'time'"
        class="date-input"
        appearance="outline"
        [ngClass]="{'input': inputFocused}"
        [@fadeIn]
      >
      <div class="time-input-wrapper">
        <input
          matInput
          [value]="time"
          formControlName={{controlName}}
          placeholder="09:00"
          (focus)="onInputFocus()"
          (blur)="onInputBlur()"
          maxlength="5"
        />
        <mat-select [(value)]="ampm" class="ampm-selector" (selectionChange)="onTimeChange($event)">
          <mat-option value="AM">AM</mat-option>
          <mat-option value="PM">PM</mat-option>
        </mat-select>
      </div>
      <mat-hint *ngIf="subtext !== ''" class="hints">
        {{subtext}}
      </mat-hint>
    </mat-form-field>

    <div *ngIf="inputType === 'file'" class="file-box" [@fadeIn]>
        <!-- For when only the file URL is available -->
        <div class="hori file" *ngIf="useFileURL" style="justify-content: space-between;">
            <div class="flex saveFileHolder" (click)="openSavedDoc()">
                <div class="flex savedFile">
                    <mat-icon *ngIf="fileURL && !fileURL.toString().toLowerCase().includes('.jpg')"
                        fontIcon="file_open" aria-hidden="false" aria-label="File button">
                    </mat-icon>

                    <img *ngIf="fileURL && fileURL.toString().toLowerCase().includes('.jpg')"
                        style="border-radius: 30px; object-fit: cover; height: 100%; width: 35px;"
                        src={{fileURL}}
                    />
                </div>
                <span class="savedFileText"><u>View Document</u></span>
            </div>
            
            <div class="flex changeImage" (click)="enableSelectImage()">
                <mat-icon fontIcon="change_circle" aria-hidden="false" aria-label="File button"></mat-icon>
                <span style="margin-left: 5px;">Change</span>
            </div>
        </div>
        <!-- For normal file upload -->
        <div class="hori file" *ngIf="!useFileURL">
            <button type="button" mat-raised-button (click)="openImageModal()" class="file-button" *ngIf="!validatingFile">
                {{ fileValid ? 'Change' : 'Select'}} <span class="file-required" *ngIf="required === 'true' && !fileValid">(required)</span>
            </button>
        
            <p class="flex file-name" [ngClass]="{'stretched': validatingFile}">
                {{fileName}}
            </p>

            <div *ngIf="validatingFile" class="flex spinner-file">
                <mat-progress-spinner mode="indeterminate" diameter="22" strokeWidth="2"></mat-progress-spinner>
            </div>
        </div>

        <div class="file-hint" *ngIf="useFileURL">
            <b style="color: #8a0000;">Uploaded from original session</b>
        </div>
        <div class="file-hint" *ngIf="!useFileURL">
            <b style="color: #8a0000;">2MB</b> size limit for <b>PDF Files</b>
        </div>

    </div>
    
    <form #f="ngForm" *ngIf="inputType === 'phone'" [formGroup]="formGroup" [@fadeIn]>
        <mat-form-field appearance="outline" [ngClass]="{'input': inputFocused}" style="width: 100%;" >
            <ngx-mat-intl-tel-input class="country-selector"
            #phoneInput
                [preferredCountries]="['ke']"
                [enablePlaceholder]="false"
                [enableSearch]="true"
                name="phone"
                describedBy="phone"
                formControlName={{controlName}}
                (focus)="onInputFocus()"
                (ngModelChange)="onInputBlur()"
            ></ngx-mat-intl-tel-input>
            <mat-error *ngIf="f.form.controls[controlName]?.errors && !(f.form.controls[controlName]?.errors?.['validatePhoneNumber'])" class="errors">
                {{formErrors[controlName]}}
            </mat-error>
            <mat-error *ngIf="f.form.controls[controlName]?.errors?.['validatePhoneNumber']" class="errors">
                Invalid phone number
            </mat-error>

            <!-- <span class="required-error" *ngIf="required === 'true' && controlName.toLowerCase() == 'phone' && !inputTouched && f.form.controls[controlName]?.value == '' " >
                Required field
            </span> -->
        </mat-form-field>
    </form>

    <div *ngIf="inputType === 'view'" class="view-box" [@fadeIn]>
        {{hint}}
    </div>
    <div class="file-hint" *ngIf="inputType === 'view'">
        {{subtext}}
    </div>
</div>

<ng-template #modalContent>
    <div [formGroup]="formGroup" class="flex center column" style="width: 100%;" [@fadeIn]>
        <!-- Preview Section -->
        <div *ngIf="previewImage" class="flex image-preview">
            <img *ngIf="isImage" [src]="previewImage" alt="Preview Image" style="width: auto; height: 100%;">
            <!-- Show PDF if the previewImage is a PDF -->
             <iframe *ngIf="!isImage" [src]="previewImage | resource" style="width: 100%; height: 400px; border: none;"></iframe>
        </div>
            
        <div class="flex center image-preview" *ngIf="!previewImage">
            <strong>Capture an image to preview</strong>
        </div>
        <!-- Action Buttons -->
        <div class="flex center" style="width: 100%; ">
            <button type="button" mat-raised-button (click)="pdf.click()" class="file-button" style="width: fit-content;">
                Browse
                <input type="file" formControlName={{controlName}} (change)="openImageDialog($event)" style="display: none;" #pdf>
            </button>

            <button type="button" mat-raised-button (click)="captureImage()" class="file-button" style="width: fit-content;">
                Take Picture
            </button>

            <button *ngIf="previewImage" type="button" mat-raised-button color="warn" (click)="removePreview()"  style="margin-left: 16px;">
                Remove Picture
            </button>
        </div>
    </div>
</ng-template>



