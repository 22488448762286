<div class="overlay"></div>

<div class="modal-content" [@fadeIn]>
  <div class="modal-header">
    <h1 class="blue title">{{enterOTPText}}</h1>
  </div>
  <div class="line-x"></div>
  <mat-dialog-content>
    <p style="font-size: medium; line-height: 1.5; color: black;">{{OTPMessage}}</p>
    <form [formGroup]="otpForm">
      <div style="width: 100%; display: flex; align-items: center; justify-content: center; margin-bottom: 50px; margin-top: 50px;">
        <ng-otp-input #ngOtpInput
          (onInputChange)="onOtpChange($event)"
          [config]="{length:6, allowNumbersOnly: true}"></ng-otp-input>
      </div>
    </form>

    <div class="timer-holder">
      <div *ngIf="countdown > 0" class="timer-box">
        <span style="font-weight: 900;">{{countdown}}</span>&nbsp;{{timerText}}
      </div>
      <div *ngIf="countdown <= 0" class="timer-box" style="background-color: #f3dcdc;">
        <span style="font-weight: 900;">{{'OTP expired'}}</span>
      </div>
      <div class="resend" (click)="sendOTP()" [ngClass]="{'ready-send': countdown <= 10}">
        <mat-icon style="margin-right: 5px;" fontIcon="{{resendIcon}}"></mat-icon>
        <u>{{requestingOTP ? ' Requesting new OTP ...' : resendOTPText}}</u>
      </div>
    </div>
    
    <div class="line-x"></div>
    <!-- Confirm Button -->
    <div class="conf-holder">
      <button mat-button class="confirm"
        (click)="verifyOtp()"
        [ngClass]="{'loadingButton': isLoading}"
        [disabled]="isLoading || !otpReady || countdown <= 0">
        <div style="display: flex; width: 100%; align-items: center;">
          {{isLoading ? 'Verifying' : submitText}}
          
          <span class="spinning" *ngIf="isLoading">
            <mat-progress-spinner
            mode="indeterminate" diameter="25" strokeWidth="4"></mat-progress-spinner>
          </span>

        </div>
      </button>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="modal-actions">
    <button class = "cancel" mat-button mat-dialog-close (click)="cancel()">Cancel</button>
  </mat-dialog-actions>
</div>
