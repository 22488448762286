import { Component, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api/api.service';
import { EstateControlService } from 'src/app/services/estate-control.service';
import { TabControlService } from 'src/app/services/tab-control.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import * as Constants from "src/app/constants/constants";
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ValidationService } from 'src/app/services/validators/validation.service';
import { CurrencyPipe } from '@angular/common';
import { extractNumericValue } from 'src/app/util/Helper';

@Component({
  selector: 'app-step4-assets',
  templateUrl: './step4-assets.component.html',
  styleUrls: ['./step4-assets.component.scss']
})
export class Step4AssetsComponent {
  @Input() showBottomButtons: boolean = true;

  formTitle: string = 'Estate Assets';
  formSubTitle: string = 'Select and fill in the assets that you own below';

  propertyTitle: string = 'Real Estate and Land (Residence, Vacation Home, Rentals, Investment Properties, etc.)';
  investmentTitle: string = 'Investments Accounts, Stock and Bonds (Non-Retirement Accounts)';
  benefitsTitle: string = 'Individual Retirement Scheme Benefits';
  policyTitle: string = 'Life Insurance Policies and Annuities';
  assetsTitle: string = 'Other Assets (e.g. sole proprietorships, Corporations, Partnerships, LLC, Stock options, Powers of appointments, potential Inheritances, intellectual properties etc.)';
  

  requiredFieldString: string = 'Required field';
  assetType: number = 0;
  assetUpdateID: any = null;

  showPropertyForm: boolean = false;
  showInvestmentForm: boolean = false;
  showBenefitForm: boolean = false;
  showPolicyForm: boolean = false;
  showAssetForm: boolean = false;

  properties: any = [];
  investments: any = [];
  benefits: any = [];
  policies: any = [];
  otherAssets: any = [];

  progressSaved: boolean = false;

  formErrors: { [key: string]: string } = {
    Location: '',
    TitleNo: '',
    Mortgage: '',
    FinancierName: '',
    FinancierType: '',
    Value: '',
    Other: '',

    Institution: '',
    AccountType: '',
    Title: '',

    Policy: '',
    Beneficiary: '',
    SumAssured: '',
    Maturity: '',

    RetirementDate: '',
    Ownership: '',
    Description: ''
  };

  validationMessages: { [key: string]: {} } = {
    Location: { required: this.requiredFieldString },
    TitleNo: { required: this.requiredFieldString },
    Mortgage: { required: this.requiredFieldString },
    FinancierName: { required: this.requiredFieldString },
    FinancierType: { required: this.requiredFieldString },
    Value: { required: this.requiredFieldString },
    Other: { required: this.requiredFieldString },

    Institution: { required: this.requiredFieldString },
    AccountType: { required: this.requiredFieldString },
    Title: { required: this.requiredFieldString },

    Policy: { required: this.requiredFieldString },
    Beneficiary: { required: this.requiredFieldString },
    SumAssured: { required: this.requiredFieldString },
    Maturity: { required: this.requiredFieldString },

    RetirementDate: { required: this.requiredFieldString },
    Ownership: { required: this.requiredFieldString },
    Description: { required: this.requiredFieldString },
    KraPinImage: { required: this.requiredFieldString }
  };

  assetForm = this._formBuilder.group({
    option1: [false],
    option2: [false],
    option3: [false],
    option4: [false],
    option5: [false]
  });

  propertyForm = this._formBuilder.group({
    Location: ['', Validators.required],
    TitleNo: ['', Validators.required],
    Mortgage: ['', Validators.required],
    Value: ['', Validators.required],
    FinancierName: [''],
    FinancierType: [''],
    Other: ['']
  });
  investmentForm = this._formBuilder.group({
    Institution: ['', Validators.required],
    AccountType: ['', Validators.required],
    Title: ['', Validators.required],
    Value: ['', Validators.required],
  });
  insuranceForm = this._formBuilder.group({
    Institution: ['', Validators.required],
    Policy: ['', Validators.required],
    Beneficiary: ['', Validators.required],
    SumAssured: ['', Validators.required],
    Maturity: ['', Validators.required]
  });
  benefitsForm = this._formBuilder.group({
    Institution: ['', Validators.required],
    AccountType: ['', Validators.required],
    Value: ['', Validators.required],
    RetirementDate: ['', Validators.required],
  });
  otherAssetsForm = this._formBuilder.group({
    Ownership: ['', Validators.required],
    Value: ['', Validators.required],
    Description: ['', Validators.required],
  });


  constructor(
    private _formBuilder: FormBuilder,
    private validationService: ValidationService,
    private notificationService: NotificationService,
    private tabControlService: TabControlService,
    public estateControlService: EstateControlService,
    private currencyPipe: CurrencyPipe,
    private apiService: ApiService
  ) {
  }

  ngOnInit(): void {
    if (this.assets) {
      this.assetForm = this._formBuilder.group({
        option1: [this.assets.option1 && this.assets.properties && this.assets.properties.length > 0 ? true : false],
        option2: [this.assets.option2 && this.assets.investments && this.assets.investments.length > 0 ? true : false],
        option3: [this.assets.option3 && this.assets.policies && this.assets.policies.length > 0 ? true : false],
        option4: [this.assets.option4 && this.assets.benefits && this.assets.benefits.length > 0 ? true : false],
        option5: [this.assets.option5 && this.assets.otherAssets && this.assets.otherAssets.length > 0 ? true : false]
      });

      if (this.assets.properties && this.assets.properties.length > 0) {
        this.properties = this.assets.properties;
        this.showPropertyForm = false;
      }
      if (this.assets.investments && this.assets.investments.length > 0) {
        this.investments = this.assets.investments;
        this.showInvestmentForm = false;
      }
      if (this.assets.policies && this.assets.policies.length > 0) {
        this.policies = this.assets.policies;
        this.showPolicyForm = false;
      }
      if (this.assets.benefits && this.assets.benefits.length > 0) {
        this.benefits = this.assets.benefits;
        this.showBenefitForm = false;
      }
      if (this.assets.otherAssets && this.assets.otherAssets.length > 0) {
        this.otherAssets = this.assets.otherAssets;
        this.showAssetForm = false;
      }
    }

    this.validationService.addOrRemoveValidationDependent(
      this.propertyForm.get("Mortgage")!,
      "FinancierName",
      this.propertyForm,
      this.formErrors,
      this.validationMessages
    );
    this.validationService.addOrRemoveValidationDependent(
      this.propertyForm.get("Mortgage")!,
      "FinancierType",
      this.propertyForm,
      this.formErrors,
      this.validationMessages
    );
    this.validationService.addOrRemoveValidationDependent(
      this.propertyForm.get("FinancierType")!,
      "Other",
      this.propertyForm,
      this.formErrors,
      this.validationMessages
    );

    this.propertyForm.valueChanges.subscribe((form) => {
      if (form.Value) {
        this.propertyForm.patchValue({
          // First replace: remove strings, 2nd replace: leading zeros
          Value: this.currencyPipe.transform(form.Value.replace(/\D/g, '').replace(/^0+/, ''), 'KSH  ', 'symbol', '1.0-0')
        }, {emitEvent: false});
      }
    });
    this.investmentForm.valueChanges.subscribe((form) => {
      if (form.Value) {
        this.investmentForm.patchValue({
          // First replace: remove strings, 2nd replace: leading zeros
          Value: this.currencyPipe.transform(form.Value.replace(/\D/g, '').replace(/^0+/, ''), 'KSH  ', 'symbol', '1.0-0')
        }, {emitEvent: false});
      }
    });
    this.insuranceForm.valueChanges.subscribe((form) => {
      if (form.SumAssured) {
        this.insuranceForm.patchValue({
          // First replace: remove strings, 2nd replace: leading zeros
          SumAssured: this.currencyPipe.transform(form.SumAssured.replace(/\D/g, '').replace(/^0+/, ''), 'KSH  ', 'symbol', '1.0-0')
        }, {emitEvent: false});
      }
    });
    this.benefitsForm.valueChanges.subscribe((form) => {
      if (form.Value) {
        this.benefitsForm.patchValue({
          // First replace: remove strings, 2nd replace: leading zeros
          Value: this.currencyPipe.transform(form.Value.replace(/\D/g, '').replace(/^0+/, ''), 'KSH  ', 'symbol', '1.0-0')
        }, {emitEvent: false});
      }
    });
    this.otherAssetsForm.valueChanges.subscribe((form) => {
      if (form.Value) {
        this.otherAssetsForm.patchValue({
          // First replace: remove strings, 2nd replace: leading zeros
          Value: this.currencyPipe.transform(form.Value.replace(/\D/g, '').replace(/^0+/, ''), 'KSH  ', 'symbol', '1.0-0')
        }, {emitEvent: false});
      }
    });
  }

  get planType(): 0 | 1 {
    return this.estateControlService.planType;
  }
  get assets(): any {
    return this.estateControlService.assets;
  }
  get loading(): boolean {
    return this.notificationService.loading;
  }
  get ConfirmOptions(): any {
    return this.estateControlService.ConfirmOptions;
  }
  get InvestmentAccountOptions(): any {
    return this.estateControlService.InvestmentAccountOptions;
  }
  get RetirementBenefitsOptions(): any {
    return this.estateControlService.RetirementBenefitsOptions;
  }
  get OwnershipOptions(): any {
    return this.estateControlService.OwnershipOptions;
  }
  get minDate() {
    return this.tabControlService.maxDOBDate;
  }
  get FinancierOptions(): any {
    return this.estateControlService.FinancierOptions;
  }
  get saveProgress(): ()=> void {
    return this.estateControlService.saveProgress;
  }

  // Property
  async addProperty() {
    this.showPropertyForm = !this.showPropertyForm;

    if (!this.showPropertyForm) {
      this.propertyForm.get('Location')?.setValue('');
      this.propertyForm.get('TitleNo')?.setValue('');
      this.propertyForm.get('Mortgage')?.setValue('');
      this.propertyForm.get('Value')?.setValue('');
      this.propertyForm.get('FinancierName')?.setValue('');
      this.propertyForm.get('FinancierType')?.setValue('');

      if (this.properties.length === 0) this.assetForm.get('option1')?.setValue(false);
    }
  }
  // Investment
  async addInvestment() {
    this.showInvestmentForm = !this.showInvestmentForm;

    if (!this.showInvestmentForm) {
      this.investmentForm.get('Institution')?.setValue('');
      this.investmentForm.get('AccountType')?.setValue('');
      this.investmentForm.get('Title')?.setValue('');
      this.investmentForm.get('Value')?.setValue('');

      if (this.investments.length === 0) this.assetForm.get('option2')?.setValue(false);
    }
  }
  // Insurance
  async addPolicy() {
    this.showPolicyForm = !this.showPolicyForm;

    if(!this.showPolicyForm) {
      this.insuranceForm.get('Institution')?.setValue('');
      this.insuranceForm.get('Policy')?.setValue('');
      this.insuranceForm.get('Beneficiary')?.setValue('');
      this.insuranceForm.get('SumAssured')?.setValue('');
      this.insuranceForm.get('Maturity')?.setValue('');

      if (this.policies.length === 0) this.assetForm.get('option3')?.setValue(false);
    }
  }
  // Benefits
  async addBenefit() {
    this.showBenefitForm = !this.showBenefitForm;

    if (!this.showBenefitForm) {
      this.benefitsForm.get('Institution')?.setValue('');
      this.benefitsForm.get('AccountType')?.setValue('');
      this.benefitsForm.get('Value')?.setValue('');
      this.benefitsForm.get('RetirementDate')?.setValue('');

      if (this.benefits.length === 0) this.assetForm.get('option4')?.setValue(false);
    }
  }
  // Assets
  async addAssets() {
    this.showAssetForm = !this.showAssetForm;

    if (!this.showAssetForm) {
      this.otherAssetsForm.get('Ownership')?.setValue('');
      this.otherAssetsForm.get('Value')?.setValue('');
      this.otherAssetsForm.get('Description')?.setValue('');

      if (this.otherAssets.length === 0) this.assetForm.get('option5')?.setValue(false);
    }
  }

  toggleOption(option: number, event: MatCheckboxChange): void {
    switch (option) {
      case 1:
        this.showPropertyForm = event.checked;
        break;
      case 2:
        this.showInvestmentForm = event.checked;
        break;
      case 3:
        this.showPolicyForm = event.checked;
        break;
      case 4:
        this.showBenefitForm = event.checked;
        break;
      case 5:
        this.showAssetForm = event.checked;
        break;
      default:
        break;
    }
    this.enforceOption(option, event.checked);
  }
  enforceOption(option: number, optionEnabled: boolean): void {
    switch (option) {
      case 1:
        if (this.properties.length > 0 && !optionEnabled) {
          this.assetForm.get('option1')?.setValue(true);
          this.notificationService.viewToast('info', 'Please remove all assets first')
        }
        break;
      case 2:
        if (this.investments.length > 0 && !optionEnabled) {
          this.assetForm.get('option2')?.setValue(true);
          this.notificationService.viewToast('info', 'Please remove all assets first')
        }
        break;
      case 3:
        if (this.policies.length > 0 && !optionEnabled) {
          this.assetForm.get('option3')?.setValue(true);
          this.notificationService.viewToast('info', 'Please remove all policies first')
        }
        break;
      case 4:
        if (this.benefits.length > 0 && !optionEnabled) {
          this.assetForm.get('option4')?.setValue(true);
          this.notificationService.viewToast('info', 'Please remove all pensions first')
        }
        break;
      case 5:
        if (this.otherAssets.length > 0 && !optionEnabled) {
          this.assetForm.get('option5')?.setValue(true);
          this.notificationService.viewToast('info', 'Please remove all assets first')
        }
        break;
      default:
        break;
    }
  }

  updateAsset = (asset: any, tableType: number): void => {
    this.assetUpdateID = asset.assetID;

    switch (tableType) {
      case 1:
        this.propertyForm.get('Location')?.setValue(asset.Location);
        this.propertyForm.get('TitleNo')?.setValue(asset.TitleLRNo);
        this.propertyForm.get('Mortgage')?.setValue(asset.Mortgage);
        this.propertyForm.get('Value')?.setValue(asset.EstimatedMarketValue);
        this.propertyForm.updateValueAndValidity();
        this.showPropertyForm = true;
        break;
      case 2:
        this.investmentForm.get('Institution')?.setValue(asset.FinancialInstitution);
        this.investmentForm.get('AccountType')?.setValue(asset.AccountType);
        this.investmentForm.get('Title')?.setValue(asset.TitleLRNo);
        this.investmentForm.get('Value')?.setValue(asset.EstimatedMarketValue);
        this.investmentForm.updateValueAndValidity();
        this.showInvestmentForm = true;
        break;
      case 3:
        this.insuranceForm.get('Institution')?.setValue(asset.FinancialInstitution);
        this.insuranceForm.get('Policy')?.setValue(asset.Policy);
        this.insuranceForm.get('Beneficiary')?.setValue(asset.BeneficiaryDetails);
        this.insuranceForm.get('SumAssured')?.setValue(asset.SumAssured);
        this.insuranceForm.get('Maturity')?.setValue(asset.MaturityDate);
        this.insuranceForm.updateValueAndValidity();
        this.showPolicyForm = true;
        break;
      case 4:
        this.benefitsForm.get('Institution')?.setValue(asset.FinancialInstitution);
        this.benefitsForm.get('AccountType')?.setValue(asset.AccountType);
        this.benefitsForm.get('Value')?.setValue(asset.FundValue);
        this.benefitsForm.get('RetirementDate')?.setValue(asset.RetirementDate);
        this.benefitsForm.updateValueAndValidity();
        this.showBenefitForm = true;
        break;
      case 5:
        this.otherAssetsForm.get('Ownership')?.setValue(asset.Ownership);
        this.otherAssetsForm.get('Description')?.setValue(asset.Description);
        this.otherAssetsForm.get('Value')?.setValue(asset.EstimatedMarketValue);
        this.otherAssetsForm.updateValueAndValidity();
        this.showAssetForm = true;
        break;
      default:
        break;
    }
  }

  deleteAsset = async(asset: any, tableType: number): Promise<void> => {
    let index;

    switch (tableType) {
      case 1:
        index = this.properties.findIndex((item: any) => item.AssetsID === asset.AssetsID);
        // console.log(`index??? ${index}`);

        if (index != -1) {
          const result = await this.removeAsset(asset.AssetsID);

          if (result === 1) {
            this.properties.splice(index, 1);
            this.properties = [...this.properties]; // This forces changes in the table components's data input
          } else {
            this.notificationService.viewToast('error', 'Error removing record');
          }
        }
        break;
      case 2:
        index = this.investments.findIndex((item: any) => item.AssetsID === asset.AssetsID);

        if (index != -1) {
          const result = await this.removeAsset(asset.AssetsID);

          if (result === 1) {
            this.investments.splice(index, 1);
            this.investments = [...this.investments];
          } else {
            this.notificationService.viewToast('error', 'Error removing record');
          }
        }
        break;
      case 3:
        index = this.policies.findIndex((item: any) => item.AssetsID === asset.AssetsID);

        if (index != -1) {
          const result = await this.removeAsset(asset.AssetsID);

          if (result === 1) {
            this.policies.splice(index, 1);
            this.policies = [...this.policies];
          } else {
            this.notificationService.viewToast('error', 'Error removing record');
          }
        }
        break;
      case 4:
        index = this.benefits.findIndex((item: any) => item.AssetsID === asset.AssetsID);

        if (index != -1) {
          const result = await this.removeAsset(asset.AssetsID);

          if (result === 1) {
            this.benefits.splice(index, 1);
            this.benefits = [...this.benefits];
          } else {
            this.notificationService.viewToast('error', 'Error removing record');
          }
        }
        break;
      case 5:
        index = this.otherAssets.findIndex((item: any) => item.AssetsID === asset.AssetsID);

        if (index != -1) {
          const result = await this.removeAsset(asset.AssetsID);

          if (result === 1) {
            this.otherAssets.splice(index, 1);
            this.otherAssets = [...this.otherAssets];
          } else {
            this.notificationService.viewToast('error', 'Error removing record');
          }
        }
        break;
      default:
        break;
    }
    this.organiseAssets();
  }
  async removeAsset(assetID: string): Promise<any> {
    try {
      if (this.estateControlService.TrustID !== null) {
        const result = await this.estateControlService.removeRecord(
          'asset',
          parseInt(assetID),
          parseInt(this.estateControlService.TrustID),
          environment.baseUrl + Constants.estateAssetsURL
        );

        return result;
      } else {
        console.log('Relevant IDs not found');
        return 0;
      }
    } catch (error) {
      console.log(':: Error !! ', error);
      return 0;
    }
  }

  replaceAsset(assetArray: any, object: any) {
    const index = assetArray.findIndex((item: any) => item.AssetsID === object.AssetsID);

    if (index != -1) {
      assetArray[index] = object;
    }
  };

  organiseAssets(saveProgress: boolean = false): void {
    const assetObject = {
      ... this.assetForm.value,
      ... {
        properties: this.properties,
        investments: this.investments,
        policies: this.policies,
        benefits: this.benefits,
        otherAssets: this.otherAssets
      }
    };

    if (this.planType === 0) {
      this.estateControlService.updateRecord(4, assetObject);
      if (!this.progressSaved && saveProgress) this.estateControlService.updatePrivateTrustProgress('Assets');
    } else {
      this.estateControlService.updateRecordWill(4, assetObject);
      if (!this.progressSaved && saveProgress) this.estateControlService.updateWillProgress('Assets');
    }
    this.progressSaved = true;
  }

  async submitForm(assetType: number, assetName: string): Promise<void> {
    this.notificationService.isLoading(true);

    try {
      let data: any = {
        AssetsName: assetName,
        TrustType: this.estateControlService.planType === 0 ? 'PrivateTrust' : 'WillCreation',
        TrustID: this.estateControlService.TrustID,
        AssetsID: this.assetUpdateID
      };

      switch (assetType) {
        case 1:
          data = {
            ...data,
            ...{
              Location: this.propertyForm.get('Location')?.value,
              TitleLRNo: this.propertyForm.get('TitleNo')?.value,
              Mortgage: this.propertyForm.get('Mortgage')?.value,
              EstimatedMarketValue: extractNumericValue(this.propertyForm.get('Value')?.value),
              FinancierName: this.propertyForm.get('FinancierName')?.value,
              FinancierType: this.propertyForm.get('FinancierType')?.value
            }
          };

          if (this.propertyForm.get('FinancierType')?.value?.toLowerCase() == 'other (specify)') {
            data = {...data, ...{FinancierType: this.propertyForm.get('Other')?.value}};
          }

          break;
        case 2:
          data = {
            ...data,
            ...{
              FinancialInstitution: this.investmentForm.get('Institution')?.value,
              AccountType: this.investmentForm.get('AccountType')?.value,
              TitleLRNo: this.investmentForm.get('Title')?.value,
              EstimatedMarketValue: extractNumericValue(this.investmentForm.get('Value')?.value)
            }
          };
          break;
        case 3:
          data = {
            ...data,
            ...{
              FinancialInstitution: this.insuranceForm.get('Institution')?.value,
              Policy: this.insuranceForm.get('Policy')?.value,
              BeneficiaryDetails: this.insuranceForm.get('Beneficiary')?.value,
              SumAssured: extractNumericValue(this.insuranceForm.get('SumAssured')?.value),
              MaturityDate: this.insuranceForm.get('Maturity')?.value
            }
          };
          break;
        case 4:
          data = {
            ...data,
            ...{
              FinancialInstitution: this.benefitsForm.get('Institution')?.value,
              AccountType: this.benefitsForm.get('AccountType')?.value,
              FundValue: extractNumericValue(this.benefitsForm.get('Value')?.value),
              RetirementDate: this.benefitsForm.get('RetirementDate')?.value
            }
          };
          break;
        case 5:
          data = {
            ...data,
            ...{
              Ownership: this.otherAssetsForm.get('Ownership')?.value,
              EstimatedMarketValue: extractNumericValue(this.otherAssetsForm.get('Value')?.value),
              Description: this.otherAssetsForm.get('Description')?.value
            }
          };
          break;
        default:
          break;
      }

      const response = await this.apiService.postRequest(
        environment.baseUrl + Constants.estateAssetsURL, data);
      // console.log('::::: RESPONSE\n', response);

      if (response.Status === 1) {
        data = {...data, ...{AssetsID: response.EstateAssetsID}}

        if (data.hasOwnProperty('AssetsName')) {
          delete data['AssetsName'];
        }
        if (data.hasOwnProperty('TrustID')) {
          delete data['TrustID'];
        }
        if (data.hasOwnProperty('TrustType')) {
          delete data['TrustType'];
        }

        switch (assetType) {
          case 1:
            if (this.assetUpdateID) {
              this.replaceAsset(this.properties, data);
            } else {
              this.properties.push(data);
            }
            this.properties = [...this.properties];
            this.addProperty();
            break;

          case 2:
            if (this.assetUpdateID) {
              this.replaceAsset(this.investments, data);
            } else {
              this.investments.push(data);
            }
            this.investments = [...this.investments];
            this.addInvestment();
            break;

          case 3:
            if (this.assetUpdateID) {
              this.replaceAsset(this.policies, data);
            } else {
              this.policies.push(data);
            }
            this.policies = [...this.policies];
            this.addPolicy();
            break;

          case 4:
            if (this.assetUpdateID) {
              this.replaceAsset(this.benefits, data);
            } else {
              this.benefits.push(data);
            }
            this.benefits = [...this.benefits];
            this.addBenefit();
            break;

          case 5:
            if (this.assetUpdateID) {
              this.replaceAsset(this.otherAssets, data);
            } else {
              this.otherAssets.push(data);
            }
            this.otherAssets = [...this.otherAssets];
            this.addAssets();
            break;

          default:
            break;
        }

        if (this.assetUpdateID) {
          this.assetUpdateID = null;
        }

        this.organiseAssets(true);
        this.notificationService.viewToast('success', 'Asset added successfully');

      } else {
        this.notificationService.viewToast('error', response.Message);
      }

    } catch (error) {
      console.error(error);
      this.notificationService.viewToast('error', 'An error occurred');
    }
    this.notificationService.isLoading(false);
  }

  async updateProgress(): Promise<void> {
    this.saveProgress();
  }

  isPageReady(): boolean {
    return (
      !this.showPropertyForm && !this.showInvestmentForm && !this.showPolicyForm && !this.showBenefitForm && !this.showAssetForm
    );
  }

  async navigateFunction(): Promise<void> {
    if (this.properties.length == 0 && this.investments.length == 0 && this.policies.length == 0 && this.benefits.length == 0 && this.otherAssets.length == 0) {
      this.notificationService.viewToast('warning', 'Please add at least One (1) Type of Asset')
    } else {
      if (this.planType === 0) {
        await this.estateControlService.changeTab(5);
      } else {
        await this.estateControlService.changeTabWill(5);
      }
      this.estateControlService.page4Done = true;
    }
  }

}
