import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CurrencyPipe } from '@angular/common';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { DateFormatDirective, FULL_FORMAT } from './util/directives/date-format.directive';
import * as Sentry from "@sentry/angular";
import { Router } from '@angular/router';

import { MAT_SELECT_CONFIG, MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatIconModule } from '@angular/material/icon';
import {MatRadioModule} from '@angular/material/radio';
import {MatChipsModule} from '@angular/material/chips';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StartComponent } from './pages/onboarding/start/start.component';
import { TrustComponent } from './pages/onboarding/trust/trust.component';
import { EstateComponent } from './pages/onboarding/estate/estate.component';
import { InputComponent } from './components/input/input.component';
import { OtpPopupComponent } from './components/otp-popup/otp-popup.component';
import { FormBottomComponent } from './components/form-bottom/form-bottom.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RadiosComponent } from './components/radios/radios.component';
import { IntlTelInputNgModule } from  'intl-tel-input-ng';
import { ToastrModule } from 'ngx-toastr';

import { Step1BasicComponent } from './pages/onboarding/trust/step1-basic/step1-basic.component';
import { Step2SettlorComponent } from './pages/onboarding/trust/step2-settlor/step2-settlor.component';
import { Step3BeneficiaryComponent } from './pages/onboarding/trust/step3-beneficiary/step3-beneficiary.component';
import { Step4GuardianComponent } from './pages/onboarding/trust/step4-guardian/step4-guardian.component';
import { Step5TrustInfoComponent } from './pages/onboarding/trust/step5-trust-info/step5-trust-info.component';
import { Step6PreviewComponent } from './pages/onboarding/trust/step6-preview/step6-preview.component';
import { Step7PaymentComponent } from './pages/onboarding/trust/step7-payment/step7-payment.component';
import { Step8CompleteComponent } from './pages/onboarding/trust/step8-complete/step8-complete.component';

import { TrustFundComponent } from './pages/info/trust-fund/trust-fund.component';
import { EstatePlanningComponent } from './pages/info/estate-planning/estate-planning.component';
import { BookCallComponent } from './components/book-call/book-call.component';

import { NgOtpInputModule } from 'ng-otp-input';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { FileValueAccessorDirective } from './util/directives/file-value-accessor.directive';
import { FileValidatorDirective } from './util/directives/file-validator.directive';
import { ApiService } from './services/api/api.service';
import { RecordEditorComponent } from './components/record-editor/record-editor.component';

import { AssignGuardianComponent } from './components/assign-guardian/assign-guardian.component';
import { Step1SettlorComponent } from './pages/onboarding/estate/appointments/step1-settlor/step1-settlor.component';
import { Step2AssetsComponent } from './pages/onboarding/estate/appointments/step2-assets/step2-assets.component';
import { Step3RefereeComponent } from './pages/onboarding/estate/appointments/step3-referree/step3-referee.component';
import { Step4ScheduleComponent } from './pages/onboarding/estate/appointments/step4-schedule/step4-schedule.component';
import { ContactInfoComponent } from './pages/onboarding/estate/appointments/step1-contact-info/contact-info.component';
import { StepSummaryComponent } from './pages/onboarding/estate/appointments/step-summary/step-summary.component';
import { AppointmentsComponent } from './pages/onboarding/estate/appointments/appointments.component';

import { OptionPopupComponent } from './pages/onboarding/estate/option-popup/option-popup.component';

import { Step1SettlorsComponent } from './pages/onboarding/estate/private-trust/step1-settlors/step1-settlors.component';
import { Step2PaymentEstateComponent } from './pages/onboarding/estate/private-trust/step2-payment-estate/step2-payment-estate.component';
import { Step2PaymentWillComponent } from './pages/onboarding/estate/will-creation/step2-payment-will/step2-payment-will.component';
import { Step3BeneficiaryEstateComponent } from './pages/onboarding/estate/private-trust/step3-beneficiary-estate/step3-beneficiary-estate.component';
import { Step4AssetsComponent } from './pages/onboarding/estate/private-trust/step4-assets/step4-assets.component';
import { Step5TrusteeComponent } from './pages/onboarding/estate/private-trust/step5-trustee/step5-trustee.component';
import { Step6EnforcerComponent } from './pages/onboarding/estate/private-trust/step6-enforcer/step6-enforcer.component';

import { Step1TestatorComponent } from './pages/onboarding/estate/will-creation/step1-testator/step1-testator.component';
import { Step3ExecutorComponent } from './pages/onboarding/estate/will-creation/step3-executor/step3-executor.component';
import { Step6WitnessComponent } from './pages/onboarding/estate/will-creation/step6-witness/step6-witness.component';

import { Step7PreviewsComponent } from './pages/onboarding/estate/private-trust/step7-previews/step7-previews.component';

import { AssetTablesComponent } from './components/asset-tables/asset-tables.component';
import { LegalConsultationComponent } from './pages/onboarding/estate/legal-consultation/legal-consultation.component';
import { EstateEditorComponent } from './components/estate-editor/estate-editor.component';
import { ModalComponent } from './components/modal/modal.component';
import { ResourcePipe } from './pipes/resource.pipe';


@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    Step1BasicComponent,
    TrustComponent,
    InputComponent,
    OtpPopupComponent,
    FormBottomComponent,
    Step2SettlorComponent,
    Step3BeneficiaryComponent,
    Step4GuardianComponent,
    RadiosComponent,
    Step5TrustInfoComponent,
    Step6PreviewComponent,
    Step7PaymentComponent,
    FileValueAccessorDirective,
    FileValidatorDirective,
    RecordEditorComponent,
    DateFormatDirective,
    Step8CompleteComponent,
    TrustFundComponent,
    EstatePlanningComponent,
    BookCallComponent,
    AssignGuardianComponent,
    EstateComponent,
    Step1SettlorComponent,
    Step2AssetsComponent,
    Step3RefereeComponent,
    Step4ScheduleComponent,
    ContactInfoComponent,
    StepSummaryComponent,
    AppointmentsComponent,
    OptionPopupComponent,
    Step1SettlorsComponent,
    Step3BeneficiaryEstateComponent,
    Step4AssetsComponent,
    Step5TrusteeComponent,
    Step6EnforcerComponent,
    Step7PreviewsComponent,
    Step2PaymentEstateComponent,
    Step1TestatorComponent,
    Step3ExecutorComponent,
    Step6WitnessComponent,
    Step2PaymentWillComponent,
    AssetTablesComponent,
    LegalConsultationComponent,
    EstateEditorComponent,
    ModalComponent,
    ResourcePipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    FormsModule,
    MatCheckboxModule,
    MatIconModule,
    MatRadioModule,
    NgOtpInputModule,
    MatNativeDateModule,
    NgxMatIntlTelInputComponent,
    MatProgressSpinnerModule,
    MatSortModule,
    MatChipsModule,
    MatTableModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    IntlTelInputNgModule.forRoot(),
    ToastrModule.forRoot({
      maxOpened: 3,
      autoDismiss: true,
      preventDuplicates: true,
      countDuplicates: true,
      resetTimeoutOnDuplicate: true,
    })
  ],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'width-overlay' }
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    { provide: MAT_DATE_FORMATS, useValue: FULL_FORMAT },
    { provide: LOCALE_ID, useValue: 'en-US' },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    CurrencyPipe,
    ApiService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {}
}
