import { AbstractControl, FormArray, ValidationErrors, ValidatorFn } from "@angular/forms";
import { environment } from "src/environments/environment";

export function createIDsValidator(checkType: 'ID' | 'PIN' | 'TEL' | 'EMAIL', checkList: AbstractControl, altList: any[]): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {
        if (!control || !checkList || !Array.isArray(checkList.value) || !Array.isArray(altList) || altList.length < 1 ) {
            return null;
        }
        const value = control.value;

        if (!value || value.length < 1) {
            return null;
        }

        const index = (checkList as FormArray).controls.indexOf(control.parent!);

        if (index === -1) {
            return null;
        }

        let valueExists: boolean = false;

        for (let i=0; i<altList.length; i++) {
            if (index === altList.length-1) {
                let status = altList[i].verified;

                if (!(altList[i].personalDone == null || altList[i].personalDone == undefined)) {
                    status = altList[i].personalDone;
                }
                const altListStatus: boolean = status;

                if (altListStatus===false) {
                    if (checkType === 'ID') {
                        valueExists = checkList.value.some((val: any) => val.IDNo === value);
                    } else if (checkType === 'PIN') {
                        valueExists = checkList.value.some((val: any) => val.KraPin === value);
                    } else if (checkType === 'TEL') {
                        valueExists = checkList.value.some((val: any) => val.Phone === value);
                    } else if (checkType === 'EMAIL') {
                        valueExists = checkList.value.some((val: any) => val.Email === value);
                    }
                }
            }
        }
        
        return altList[altList.length-1].verified === false && valueExists && index === checkList.value.length-1 ? {exist: true}: null;
    }
}

export function createIDsValidatorEstate(checkType: 'ID' | 'PIN' | 'TEL' | 'EMAIL', checkList: AbstractControl, altList: any[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control || !(checkList instanceof FormArray) || !Array.isArray(altList) || altList.length < 1) {
            return null;
        }

        const value = control.value;
        if (!value || value.length < 1) {
            return null;
        }

        const parent = control.parent;
        const index = parent ? checkList.controls.indexOf(parent) : -1;

        if (index === -1) {
            return null;
        }

        const valueExists = altList.some((alt, i) => {
            if (index === altList.length - 1) {
                switch (checkType) {
                    case 'ID':
                        return alt.IDNo === value && index !== i;
                    case 'PIN':
                        return alt.KraPin === value && index !== i;
                    case 'TEL':
                        return alt.Phone === value && index !== i;
                    case 'EMAIL':
                        return alt.Email === value && index !== i;
                    default:
                        return false;
                }
            }
            return false;
        });

        return valueExists ? { exist: true } : null;
    };
}


// export function createIDsValidatorEstate(checkType: 'ID' | 'PIN' | 'TEL' | 'EMAIL', checkList: AbstractControl, altList: any[]): ValidatorFn {
//     return (control:AbstractControl) : ValidationErrors | null => {
//         if (!control || !checkList || !Array.isArray(checkList.value) || !Array.isArray(altList) || altList.length < 1 ) {
//             return null;
//         }
//         const value = control.value;

//         if (!value || value.length < 1) {
//             return null;
//         }

//         const index = (checkList as FormArray).controls.indexOf(control.parent!);

//         if (index === -1) {
//             return null;
//         }

//         let valueExists: boolean = false;

//         for (let i=0; i<altList.length; i++) {
//             if (index === altList.length-1) {
//                 console.log('list', altList)
//                 if (checkType === 'ID') {
//                     valueExists = checkList.value.some((val: any, ind: number) => val.IDNo === value && index != ind);
//                 } else if (checkType === 'PIN') {
//                     valueExists = checkList.value.some((val: any, ind: number) => val.KraPin === value && index != ind);
//                 } else if (checkType === 'TEL') {
//                     valueExists = checkList.value.some((val: any, ind: number) => val.Phone === value && index != ind);
//                 } else if (checkType === 'EMAIL') {
//                     valueExists = checkList.value.some((val: any, ind: number) => val.Email === value && index != ind);
//                 }
//             }
//         }
        
//         return valueExists ? {exist: true}: null;
//     }
// }

export function createAmountAbove5KValidator(): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {
        const value = control.value;

        let minAmount = 5000;
        
        if (!environment.production) minAmount = 1;

        if (!value || value.length < 6) {
            return null;
        }

        const numericValue = parseInt(value.replace(/\D/g,''));

        const isBelow5K: boolean = numericValue < minAmount;

        const valueValid = !isBelow5K;

        return !valueValid ? {cMin: true}: null;
    }
}
